import React, { Component } from 'react';
import LivelyUpload from '@livelyvideo/upload-core/lib/index';
import { API_HOST } from 'utils/constants';
import store from 'utils/store';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginTop: theme.spacing.unit,
		marginBottom: theme.spacing.unit * 2
	},
	button: {
		marginRight: theme.spacing.unit
	},
	hide: {
		display: 'none'
	}
});

class Upload extends Component {
	state = {
		percent: 0,
		fileName: null,
		disabled: null
	};

	constructor() {
		super();

		this.upload = null;
		this.uploadRef = React.createRef();
	}

	componentDidMount() {
		this.upload = new LivelyUpload(this.uploadRef.current, {
			host: 'dev.livelyvideo.tv',
			multiple: false,
			authUrl: `${API_HOST}/api/upload/access-token?token=${store.getItem('at')}`
		});

		this.upload.on('progress', this.handleOnProgress);
		this.upload.on('addedfile', this.handleOnAddedFile);
		this.upload.on('failure', this.handleOnFailure);
		this.upload.on('start', this.handleOnStart);
		this.upload.on('success', this.handleOnSuccess);
	}

	componentWillUnmount() {
		this.upload.destroy();
	}

	handleOnProgress = (a, b, c, percent) => {
		this.setState({
			percent
		});
	};

	handleOnAddedFile = file => {
		this.setState({
			fileName: file.name
		});
	};

	handleOnClick = () => {
		this.upload.uploadInput.click();
	};

	handleOnStart = (file, uploadId) => {
		this.setState({
			disabled: true
		});
		this.props.onStart(file, uploadId);
	};

	handleOnSuccess = (file, uploadUrl, uploadId) => {
		this.setState({
			disabled: false
		});
		this.props.onSuccess(file, uploadUrl, uploadId);
	};

	handleOnFailure = (file, reason) => {
		this.setState({
			disabled: false
		});
		this.props.onFailure(file, reason);
	};

	render() {
		const { classes } = this.props;
		const { percent, fileName, disabled } = this.state;

		return (
			<div className={classes.root}>
				<div className={classes.hide} ref={this.uploadRef} />
				<Button
					disabled={disabled}
					className={classes.button}
					color="primary"
					variant="contained"
					onClick={this.handleOnClick}
				>
					Upload
				</Button>
				{fileName && (
					<Typography variant="caption">{`${percent}% - ${fileName}`}</Typography>
				)}
			</div>
		);
	}
}

export default withStyles(styles)(Upload);
