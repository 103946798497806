import { errorHandler } from 'modules/common';
import { api } from 'utils/request';
import get from 'lodash.get';
import { AUTH_SUCCESS } from './authentication';

/*
 * Actions
 */
const reducerName = 'session';
export const SESSION_REQUESTING = `${reducerName}/SESSION_REQUESTING`;
export const SESSION_SUCCESS = `${reducerName}/SESSION_SUCCESS`;
export const SESSION_FAILURE = `${reducerName}/SESSION_FAILURE`;
export const SESSION_CLEAR = `${reducerName}/SESSION_CLEAR`;

/*
 * Reducer
 */
const initialState = {
	requesting: true,
	success: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SESSION_REQUESTING:
			return {
				...state,
				requesting: true
			};

		case AUTH_SUCCESS:
		case SESSION_SUCCESS:
			return {
				...state,
				requesting: false,
				success: true
			};

		case SESSION_FAILURE:
			return {
				...state,
				requesting: false,
				error: action.error
			};

		case SESSION_CLEAR:
			return {
				...initialState
			};

		default:
			return state;
	}
};

/*
 * Action Creators
 */
export const requestSession = () => dispatch => {
	dispatch({
		type: SESSION_REQUESTING
	});

	api({
		url: '/api/session'
	})
		.then(response => {
			dispatch({
				type: SESSION_SUCCESS,
				token: get(response, 'data.token.access_token'),
				userUuid: get(response, 'data.token.user_uuid')
			});
		})
		.catch(error => {
			dispatch({
				type: SESSION_FAILURE,
				error
			});
			dispatch(errorHandler(error));
		});
};

export const clearSession = () => ({
	type: SESSION_CLEAR
});

/*
 * Selectors
 */
export const getSession = state => get(state[reducerName], 'success');
export const getRequesting = state => get(state[reducerName], 'requesting');
