import React from 'react';
import Simple from './Simple';

function withLayout(Component) {
	function WithLayout(props) {
		const { classes, ...rest } = props;

		return (
			<Simple {...rest}>
				<Component {...props} />
			</Simple>
		);
	}

	return WithLayout;
}

export default withLayout;
