import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';

import { requestSession, getSession, getRequesting } from 'modules/session';
import { initialize } from 'modules/aws';
import { getToken } from 'modules/authentication';
import { requestAppVersion, getAppVersionFromLocalStorage, getAppVersion } from 'modules/app-version';
import { createStructuredSelector } from 'reselect';

import Entry from 'pages/authentication/Entry';
import Loading from 'pages/loading';

import Find from 'pages/patients/Find';
import Patient from 'pages/patient';

import Snackbar from 'containers/Snackbar';
import { history } from './store';

import './index.css';

class App extends Component {
	constructor(props) {
		super(props);

		props.requestSession();
		props.initialize();
		props.requestAppVersion();
	}

	render() {
		const { requesting, token, session, version } = this.props;
		// if version in local storage is not the same as what we received
		// from ver.json, then reload.
		if (version && getAppVersionFromLocalStorage() !== version) {
			window.location.reload(true);
		}

		if (requesting) {
			return <Loading />;
		}

		if (!token || !session) {
			return (
				<div>
					<Entry />
					<Snackbar />
				</div>
			);
		}

		return (
			<div>
				<Router history={history}>
					<Switch>
						<Route exact path="/" component={Find} />
						<Route exact path="/patients/find" component={Find} />
						<Route exact path="/patients/:uuid" component={Patient} />
					</Switch>
				</Router>
				<Snackbar />
			</div>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	requesting: getRequesting,
	token: getToken,
	session: getSession,
	version: getAppVersion
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			requestSession,
			initialize,
			requestAppVersion
		},
		dispatch
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(App);
