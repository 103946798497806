import React, { Component } from 'react';

// Redux
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { deauthenticate } from 'modules/authentication';
import { push } from 'connected-react-router';

// UI
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { withStyles } from '@material-ui/core/styles';

import Logo from 'components/logo';
import { version, name } from '../../package.json';

const styles = theme => ({
	grow: {
		flexGrow: 1
	},
	content: {
		marginTop: theme.spacing.unit * 8,
	},
	iconButton: {
		marginRight: -12
	},
	iconButtonLeft: {
		marginLeft: -12,
		paddingRight: 20
	},
	paper: {
		position: 'absolute',
		width: '60%',
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing.unit * 4,
		outline: 'none'
	},
	capitalize: {
		textTransform: 'capitalize'
	}
});

class Header extends Component {
	state = {
		anchorEl: null,
		isDialogOpen: false
	};

	handleMenu = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	handleSignOut = () => {
		this.setState({ anchorEl: null });
		this.props.deauthenticate();
	};

	handleBack = () => {
		this.setState({
			anchorEl: null
		});

		this.props.push('/');
	};

	handleDialogOpen = () => {
		this.setState({ anchorEl: null, isDialogOpen: true });
	};

	handleModalClose = () => {
		this.setState({ isDialogOpen: false });
	};

	getModalStyle = () => {
		return {
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)'
		};
	};

	render() {
		const {
			children,
			classes,
			title,
			back,
			onHeaderClick,
			noMargin
		} = this.props;
		const { anchorEl } = this.state;

		const open = Boolean(anchorEl);

		return (
			<React.Fragment>
				<AppBar position="fixed">
					<Toolbar>
						{back && (
							<IconButton
								className={classes.iconButtonLeft}
								color="inherit"
								onClick={this.handleBack}
							>
								<ArrowBackIcon />
							</IconButton>
						)}
						<Typography
							variant="h6"
							color="inherit"
							className={classes.grow}
							onClick={onHeaderClick}
						>
							{title}
						</Typography>
						<IconButton
							className={classes.iconButton}
							color="inherit"
							onClick={this.handleMenu}
						>
							<MoreVertIcon />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right'
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right'
							}}
							open={open}
							onClose={this.handleClose}
						>
							<MenuItem onClick={this.handleSignOut}>Sign out</MenuItem>
							<MenuItem onClick={this.handleDialogOpen} className={classes.capitalize}>About {name}</MenuItem>
						</Menu>
					</Toolbar>
					<Dialog open={this.state.isDialogOpen} onClose={this.handleModalClose}>
						<DialogContent>
							<Logo />
							<Typography variant="body2" align="center">Version {version}</Typography>
							<Typography variant="body2" align="center">LivelyVideo</Typography>
						</DialogContent>
					</Dialog>
				</AppBar>
				<div className={noMargin ? '' : classes.content}>{children}</div>
			</React.Fragment>
		);
	}
}

Header.defaultProps = {
	title: 'Find Patient',
	back: false,
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			deauthenticate,
			push
		},
		dispatch
	);

export default compose(
	withStyles(styles),
	connect(
		null,
		mapDispatchToProps
	)
)(Header);
