import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getOpen, getSnack, clearSnack } from 'modules/snackbar';

// UI
import Snackbar from '@material-ui/core/Snackbar';

class AppSnackbar extends PureComponent {
	handleClose = () => {
		const { clearSnack } = this.props;

		clearSnack();
	};

	render() {
		const { open, snack } = this.props;
		const { message, action = null } = snack;

		return (
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				open={open}
				autoHideDuration={6000}
				onClose={this.handleClose}
				message={message}
				action={action}
			/>
		);
	}
}

AppSnackbar.propTypes = {
	open: PropTypes.bool.isRequired,
	snack: PropTypes.object
};

const mapStateToProps = createStructuredSelector({
	open: getOpen,
	snack: getSnack
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			clearSnack
		},
		dispatch
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AppSnackbar);
