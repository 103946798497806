import get from 'lodash.get';
import errorToReasons from 'utils/error-to-reasons';
import { api } from 'utils/request';
import { errorHandler } from 'modules/common';
import stringHash from 'string-hash';
import { getInstance } from 'modules/aws';

const uuidv4 = require('uuid/v4');

/*
 * Actions
 */
const reducerName = 'find';
export const FIND_REQUESTING = `${reducerName}/FIND_REQUESTING`;
export const FIND_SUCCESS = `${reducerName}/FIND_SUCCESS`;
export const FIND_FAILURE = `${reducerName}/FIND_FAILURE`;
export const FIND_CLEAR = `${reducerName}/FIND_CLEAR`;

/*
 * Reducer
 */
const initialState = {
	requesting: false,
	exists: false,
	uuid: null,
	age: 0
};

export default (state = initialState, action) => {
	switch (action.type) {
		case FIND_REQUESTING:
			return {
				...state,
				requesting: true
			};

		case FIND_SUCCESS:
			return {
				...state,
				requesting: false,
				exists: action.exists,
				uuid: action.uuid,
				age: action.age
			};

		case FIND_FAILURE:
			return {
				...state,
				requesting: false
			};

		case FIND_CLEAR:
			return {
				...initialState
			};

		default:
			return state;
	}
};

/*
 * Action Creators
 */
export const findPatient = blob => (dispatch, getState) => {
	const state = getState();
	const aws = getInstance(state);

	dispatch({
		type: FIND_REQUESTING
	});

	aws.upload(
		{
			Body:blob, ContentType:blob.type,
			Key: `${uuidv4()}.${(blob.name || '').split('.')[1] || 'jpg'}`
		},
		(error, response) => {
			if (error) {
				dispatch({ type:FIND_FAILURE, reasons:errorToReasons(error) });
			}

			const elementId = stringHash(`${blob.type}${blob.size}`);

			api({
				method: 'POST',	url: '/api/patients/find',
				data: { elementId, S3BktNm:response.Bucket, S3BktKey:response.Key }
			})
			.then(response => {
				dispatch({
					type: FIND_SUCCESS,
					exists: get(response, 'data.exists'),
					uuid: get(response, 'data.uuid'),
					age: get(response, 'data.age')
				});
			})
			.catch(error => {
				dispatch({
					type: FIND_FAILURE,
					error
				});
				dispatch(errorHandler(error));
			});
		}
	);
};

export const clearFind = () => ({
	type: FIND_CLEAR
});

/*
 * Selectors
 */
export const getRequesting = state => get(state[reducerName], 'requesting');
export const getExists = state => get(state[reducerName], 'exists');
export const getUuid = state => get(state[reducerName], 'uuid');
export const getAge = state => get(state[reducerName], 'age');

