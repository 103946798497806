import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getOpen } from 'modules/snackbar';

// UI
import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	root: {
		position: 'fixed',
		bottom: theme.spacing.unit * 2,
		transition: theme.transitions.create('bottom'),
		transitionDuration: theme.transitions.duration.enteringScreen
	},
	center: {
		left: 0,
		right: 0,
		margin: '0 auto'
	},
	right: {
		right: theme.spacing.unit
	},
	left: {
		left: theme.spacing.unit
	},
	open: {
		bottom: theme.spacing.unit * 8
	}
});

class SmartFab extends PureComponent {
	render() {
		const { classes, children, open, position, dispatch, ...rest } = this.props;

		const rootClass = classnames({
			[classes.root]: true,
			[classes.center]: position === 'center',
			[classes.right]: position === 'right',
			[classes.left]: position === 'left',
			[classes.open]: open
		});

		return (
			<Fab color="primary" {...rest} className={rootClass}>
				{children}
			</Fab>
		);
	}
}

SmartFab.propTypes = {
	open: PropTypes.bool.isRequired,
	position: PropTypes.string.isRequired
};

const mapStateToProps = createStructuredSelector({
	open: getOpen
});

export default compose(
	withStyles(styles),
	connect(
		mapStateToProps,
		null
	)
)(SmartFab);
