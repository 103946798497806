import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import isInt from 'validator/lib/isInt';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import QRCode from 'qrcode.react';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import QRCodeIcon from './QRCodeIcon';
import moment from 'moment';

import EditIcon from '@material-ui/icons/Edit';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import classnames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { updatePatient } from 'modules/patients/patient';

const styles = theme => ({
	root: {
		padding: theme.spacing.unit * 2,
		paddingTop: 0,
		paddingRight: theme.spacing.unit / 2,
		display: 'flex'
	},
	avatar: {
		width: 56,
		height: 56,
		background: '#eee',
		marginRight: theme.spacing.unit * 2,
		marginTop: theme.spacing.unit * 1
	},
	info: {
		flex: 2
	},
	primary: {
		color: '#222'
	},
	secondary: {
		color: '#444'
	},
	content: {
		padding: theme.spacing.unit * 2
	},
	zid: {
		wordBreak: 'break-all'
	},
	qrContainer: {
		padding: theme.spacing.unit * 2,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	floatLeft: {
		float: 'left'
	},
	paddingLeft: {
		paddingLeft: theme.spacing.unit
	},
	width: {
		width: '40%'
	},
	margin: {
		margin: '2%'
	},
	marginTop: {
		marginTop: theme.spacing.unit * 1
	},
	errorMessage: {
		color: 'red'
	},
	copy: {
		float: 'right',
		minWidth: theme.spacing.unit * 7,
		textTransform: 'capitalize',
		padding: 0
	}
});

class Header extends Component {
	static defaultProps = {
		feetOptions: [1, 2, 3, 4, 5, 6, 7, 8],
		inchOptions: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
	};

	constructor(props) {
		super(props);
		this.state = {
			drwrOpn: false,
			editClick: false,
			heightUnitsPart1: String(props.patient.height.imperial.value.ft),
			heightUnitsPart2: String(props.patient.height.imperial.value.in),
			weightUnitsPart1: String(props.patient.weight.imperial.value.lbs),
			weightUnitsPart2: String(props.patient.weight.imperial.value.oz),
			errors: {
				heightUnitsPart1: null,
				heightUnitsPart2: null,
				weightUnitsPart1: null,
				weightUnitsPart2: null
			}
		};
	}

	setDrwr = () => {
		this.setState({ drwrOpn: !this.state.drwrOpn });
	};

	editClicked = () => {
		this.setState({ editClick: !this.state.editClick });
	};

	handleOnCancel = () => {
		this.setState({ editClick: !this.state.editClick });
	};

	handleOnChange = key => e => {
		this.setState({
			[key]: e.target.value
		});
	};

	handleMeasurementChange = key => e => {
		const input = e.target.value;
		let cond = {gt: 0};
		if (key === 'weightUnitsPart2') {
			cond = {gte: 0};
		}
		if (isInt(input, cond)) {
			this.setState({
				[key]: input,
				errors: {
					[key]: null
				}
			});
			return;
		}
		this.setState({
			[key]: input,
			errors: {
				[key]: 'Invalid measurement provided.'
			}
		});
	};

	handleOnSubmit = () => {
		this.setState({ editClick: !this.state.editClick });
		const { patient, updatePatient } = this.props;
		const { errors, ...rest } = this.state;
		const changed   = {ht: false, wt: false};
		const origHtFt  = patient.height && patient.height.imperial && patient.height.imperial.value && patient.height.imperial.value.ft;
		const origHtIn  = patient.height && patient.height.imperial && patient.height.imperial.value && patient.height.imperial.value.in;
		const origWtLbs = patient.weight && patient.weight.imperial && patient.weight.imperial.value && patient.weight.imperial.value.lbs;
		const origWtOz  = patient.weight && patient.weight.imperial && patient.weight.imperial.value && patient.weight.imperial.value.oz;
		const currHtFt  = parseFloat(this.state.heightUnitsPart1);
		const currHtIn  = parseFloat(this.state.heightUnitsPart2);
		const currWtLbs = parseFloat(this.state.weightUnitsPart1);
		const currWtOz  = parseFloat(this.state.weightUnitsPart2);
		if (origHtFt && (origHtFt !== currHtFt || origHtIn !== currHtIn)) {
			changed.ht = true;
		}
		if (origWtLbs && (origWtLbs !== currWtLbs || origWtOz !== currWtOz)) {
			changed.wt = true;
		}
		updatePatient(patient.uuid, rest, changed);
	};

	render() {
		const { classes, patient, feetOptions, inchOptions, requesting } = this.props;
		const { heightUnitsPart1, heightUnitsPart2, weightUnitsPart1, weightUnitsPart2, errors } = this.state;
		const age = moment.utc().diff(patient.dob, 'years');

		patient.heightToDisplay = patient.height.imperial.display;
		patient.weightToDisplay = patient.weight.imperial.display;
		return (
			<div>
				<div className={classes.root}>
					<Avatar
						className={classes.avatar}
						alt={`${patient.familyName},${patient.givenName}`}
						src={patient.picUrl}
					/>
					<div className={classes.info}>
						<Typography variant="h6" className={classes.primary}>
							{`${patient.familyName}, ${patient.givenName}`}
						</Typography>
						<div>
							<Typography variant="body2" className={classnames(classes.secondary)}>
								{`${patient.genderHuman} - ${patient.age}`} 
							</Typography>
							<Typography variant="body2" className={classnames(classes.secondary, classes.floatLeft)}>
								{`${patient.heightToDisplay}, ${patient.weightToDisplay}`} 
							</Typography>
							<EditIcon className={classes.paddingLeft} onClick={this.editClicked}/>
						</div>
						{ age < 18 ? (
							<Typography variant="body2" className={classes.secondary}>
								{`Guardian: ${patient.guardianName}`}
							</Typography>
						): null }
					</div>
					<IconButton onClick={this.setDrwr}>
						<QRCodeIcon />
					</IconButton>
				</div>
				<Drawer
					anchor="top"
					open={this.state.drwrOpn}
					onClick={this.setDrwr.bind(this)}
				>
					<div className={classes.qrContainer}>
						<QRCode value={patient.uuid} renderAs="svg" />
					</div>
					<Divider />
					<div className={classes.content}>
						<Typography gutterBottom className={classes.zid}>
							Zetonium ID
						</Typography>
						<Typography className={classes.zid}>
							{patient.zetoniumId}
							<CopyToClipboard
								text={patient.zetoniumId}>
								<Button color="primary" variant="contained" className={classes.copy}>Copy</Button>
							</CopyToClipboard>
						</Typography>
						<Typography gutterBottom className={classes.marginTop}>User since: {moment(patient.created).format("MMMM Do, YYYY")}</Typography>
						{patient.account && (typeof patient.account.balance !== 'undefined') && !isNaN(patient.account.balance) ? (
							<Typography gutterBottom className={classes.marginTop}>Remaining asset submissions: {Math.floor(patient.account.balance/patient.account.costPerSubmission)} Asset(s)</Typography>
							) : (
							<Typography gutterBottom className={classnames(classes.marginTop, classes.errorMessage)}>Remaining asset submissions: {patient.account && patient.account.error}</Typography>
							)}
						<Typography gutterBottom className={classes.marginTop}>Note: Asset submissions can be composed of multiple media files, but represent a single posting of information to the blockchain. If your account requires additional funding to allow for additional records, please contact support@recare.com.</Typography>
					</div>
				</Drawer>
				<Dialog open={this.state.editClick} onClose={this.handleOnCancel}>
					<DialogTitle>Edit Patient Height/Weight</DialogTitle>
					<div>
						{/*
							FIXME: The following currently assumes imperial measurement
							settings as the provider's preference. This will be later
							changed to ask for the provider's preference and store the
							same in the database. Changes will then be made to the client
							to display appropriate fields.
						*/}
						<FormControl required className={classnames(classes.width, classes.margin)}>
							<InputLabel htmlFor="heightUnitsPart1">Height(ft)</InputLabel>
							<Select
								native
								value={heightUnitsPart1}
								onChange={this.handleOnChange('heightUnitsPart1')}
								inputProps={{ name: 'Height', id: 'heightUnitsPart1' }}
							>
								{feetOptions.map(opt => {
									return <option key={opt} value={opt}>{opt}</option>;
								})}
							</Select>
							<FormHelperText>Required</FormHelperText>
						</FormControl>
						<FormControl className={classnames(classes.width, classes.margin)}>
							<InputLabel htmlFor="heightUnitsPart2">Height(in)</InputLabel>
							<Select
								native
								value={heightUnitsPart2}
								onChange={this.handleOnChange('heightUnitsPart2')}
								inputProps={{ name: 'Height', id: 'heightUnitsPart2' }}
							>
								<option key="inchOption" value="0">0</option>
								{inchOptions.map(opt => {
									return <option key={opt} value={opt}>{opt}</option>;
								})}
							</Select>
						</FormControl>
					</div>
					<div>
						<TextField
							autoComplete="off"
							className={classnames(classes.width, classes.margin)}
							onChange={this.handleMeasurementChange('weightUnitsPart1')}
							value={weightUnitsPart1}
							type="number"
							margin="dense"
							id="weightUnitsPart1"
							error={!!errors.weightUnitsPart1}
							helperText={errors.weightUnitsPart1}
							label="Weight"
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">lbs</InputAdornment>
								)
							}}
							required
						/>
						<TextField
							autoComplete="off"
							className={classnames(classes.width, classes.margin)}
							onChange={this.handleMeasurementChange('weightUnitsPart2')}
							value={weightUnitsPart2}
							type="number"
							margin="dense"
							id="weightUnitsPart2"
							error={!!errors.weightUnitsPart2}
							helperText={errors.weightUnitsPart2}
							label="Weight"
							InputProps={{
								endAdornment: <InputAdornment position="end">oz</InputAdornment>
							}}
						/>
					</div>
					<DialogActions>
						<Button onClick={this.editClicked} color="primary">
							Cancel
						</Button>
						<Button
							onClick={this.handleOnSubmit}
							variant="contained"
							color="primary"
							disabled={this.disabled || requesting}
						>
							{requesting ? (
								<CircularProgress style={{ color: '#FFF' }} size={24} />
							) : (
								'Submit'
							)}
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

Header.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	patient: state.patient.patient
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			updatePatient
		},
		dispatch
	);

export default compose(
	withStyles(styles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(Header);
