export default {
  options: [ 'list', 'link'],
  link: {
  	inDropdown: true,
  	defaultTargetOption: '_blank'
  },
  list: {
  	inDropdown: true,
  	options: ['unordered', 'ordered']
  },
}
