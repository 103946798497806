import React, { PureComponent } from 'react';

// Redux
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { accessPatient, getRequesting, getPasswordError } from 'modules/patients/patient';
import { clearFind } from 'modules/patients/find';
import { createStructuredSelector } from 'reselect';

// UI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';

class AccessForm extends PureComponent {
	state = {
		password: '',
		showPassword: false
	};

	handleOnCancel = () => {
		this.props.onCancel();
	};

	handleOnSubmit = e => {
		e.preventDefault();

		const { uuid, accessPatient } = this.props;
		const { password } = this.state;

		accessPatient(uuid, password);
	};

	handleOnChange = e => {
		this.setState({
			password: e.target.value
		});
	};

	handleClickShowPassword = () => {
		this.setState(prevState => ({
			showPassword: !prevState.showPassword
		}));
	};

	render() {
		const { requesting, passwordError } = this.props;
		const { password, showPassword } = this.state;

		return (
			<Dialog open onClose={this.handleOnCancel}>
				<DialogTitle>Guardian Permission</DialogTitle>
				<DialogContent>
					<DialogContentText>Enter the password</DialogContentText>
					<form onSubmit={this.handleOnSubmit}>
						<TextField
							autoFocus
							onChange={this.handleOnChange}
							value={password}
							type={showPassword ? 'text' : 'password'}
							margin="dense"
							id="password"
							label="Password"
							error={!!passwordError}
							helperText={passwordError}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword}>
											{this.state.showPassword ? (<Visibility />) : (<VisibilityOff />)}
										</IconButton>
									</InputAdornment>
								)
							}}
						/>
					</form>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleOnCancel} color="primary">
						Cancel
					</Button>
					<Button
						onClick={this.handleOnSubmit}
						variant="contained"
						color="primary"
						type="submit"
						disabled={requesting}
					>
						{requesting ? (
							<CircularProgress style={{ color: '#FFF' }} size={24} />
						) : (
							'Submit'
						)}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	requesting: getRequesting,
	passwordError: getPasswordError
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			accessPatient,
			clearFind
		},
		dispatch
	);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(AccessForm);
