import React, { Component } from 'react';
import Div100vh from 'react-div-100vh';

class Viewport extends Component {
	constructor() {
		super();

		this._fullscreenRef = React.createRef();
	}

	render() {
		const { children } = this.props;

		return <Div100vh ref={this._fullscreenRef}>{children}</Div100vh>;
	}
}

export default Viewport;
