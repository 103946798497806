import 'whatwg-fetch';
import axios from 'axios';
import { API_HOST } from './constants';
import store from './store';

/**
 * Parses the JSON returned by a network request
 * Always parses JSON so we can grab response.reasons
 *
 * @param  {object} response A response from a network request
 * @return {object} The parsed JSON, status from the response
 */
function parseJSON(response) {
  return new Promise((resolve, reject) => response.text()
    .then((text) => {
      let json = {};
      if (text) {
        try {
          json = JSON.parse(text);
        } catch (err) {
          reject(err);
        }
      }

      resolve({
        status: response.status,
        ok: response.ok,
        json,
      })
    })
  );
}

export const api = options => {
  options.headers = { ...options.headers };
  
  const at = store.getItem('at');

  if (at) {
    options.headers['Authorization'] = `Bearer ${at}`;
  }

  options.url = `${API_HOST}${options.url}`;

  return axios(options);
};

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {Promise}          The request promise
 */
export default function request(uri, opts) {
  return new Promise((resolve, reject) => {
    const at = store.getItem('at');

    let options = opts || {};
    options.body = JSON.stringify(options.body);
    options.headers = options.headers || {};
    options.headers['Accept'] = 'application/json';
    options.headers['Content-Type'] = 'application/json';
    options.headers['Authorization'] = `Bearer ${at}`;

    fetch(`${API_HOST}${uri}`, options)
      .then(parseJSON)
      .then((response) => {
        if (response.ok) {
          return resolve(response);
        }

        // extract the error from the server's json
        return reject(response);
      })
      .catch((error) => reject(error));
  });
}
