import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

const styles = theme => ({
	button: {
		position: 'fixed',
		right: theme.spacing.unit + 2,
		bottom: theme.spacing.unit + 2
	}
});

function AddEncounterButton(props) {
	const { classes } = props;
	return (
		<Fab onClick={props.onClick} color="primary" className={classes.button}>
			<AddIcon />
		</Fab>
	);
}

AddEncounterButton.propTypes = {
	classes: PropTypes.object.isRequired,
	onClick: PropTypes.func.isRequired
};

export default withStyles(styles)(AddEncounterButton);
