import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import authentication from 'modules/authentication';
import code from 'modules/authentication/code';
import encounters from 'modules/encounters/encounters';
import patient from 'modules/patients/patient';
import find from 'modules/patients/find';
import session from 'modules/session';
import snackbar from 'modules/snackbar';
import media from 'modules/media';
import aws from 'modules/aws';
import appVersion from 'modules/app-version';

const createRootReducer = history =>
	combineReducers({
		router: connectRouter(history),
		authentication,
		code,
		encounters,
		patient,
		session,
		appVersion,
		snackbar,
		media,
		aws,
		find
	});

export default createRootReducer;
