import React, { PureComponent } from 'react';
import moment from 'moment';
import isBefore from 'validator/lib/isBefore';
import isInt from 'validator/lib/isInt';

// Redux
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { createPatient, getRequesting } from 'modules/patients/patient';
import { getExists, getUuid, clearFind } from 'modules/patients/find';
import { createStructuredSelector } from 'reselect';

// UI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import classnames from 'classnames';

const styles = theme => ({
	width: {
		width: '40%'
	},
	margin: {
		margin: '2%'
	}
});

class PatientCreateDialog extends PureComponent {
	static defaultProps = {
		feetOptions: [1, 2, 3, 4, 5, 6, 7, 8],
		inchOptions: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
	};
	state = {
		password: '',
		confirmPassword: '',
		gender: 'U',
		dob: moment
			.utc()
			.subtract(3, 'years')
			.startOf('year')
			.format('YYYY-MM-DD'),
		givenName: '',
		familyName: '',
		guardianName: '',
		// The following currently assumes imperial measurement
		// settings as the provider's preference. This will be later
		// changed to ask for the provider's preference and store the
		// same in the database. Changes will then be made to the client
		// to display appropriate fields.
		heightUnitsPart1: '5', // represents feet
		heightUnitsPart2: '2', // represents inches
		weightUnitsPart1: '80', // represents lbs
		weightUnitsPart2: '0', // represent oz
		errors: {
			dob: null,
			heightUnitsPart1: null,
			heightUnitsPart2: null,
			weightUnitsPart1: null,
			weightUnitsPart2: null
		},
		guardianNameReq: true
	};

	get disabled() {
		const {
			password,
			confirmPassword,
			gender,
			dob,
			givenName,
			familyName,
			guardianName,
			heightUnitsPart1,
			weightUnitsPart1,
			guardianNameReq
		} = this.state;

		return (
			!password ||
			!confirmPassword ||
			!dob ||
			!gender ||
			!givenName ||
			(guardianNameReq && !guardianName) ||
			!familyName ||
			!givenName ||
			!heightUnitsPart1 ||
			!weightUnitsPart1 ||
			password !== confirmPassword
		);
	}

	handleOnChange = key => e => {
		this.setState({
			[key]: e.target.value
		});
	};

	handleOnClose = () => {
		this.props.clearFind();
	};

	handleOnSubmit = () => {
		const { uuid, createPatient } = this.props;
		const { errors, ...rest } = this.state;
		createPatient(uuid, rest);
	};

	handleMeasurementChange = key => e => {
		const input = e.target.value;
		if (isInt(input, {gt: 0}) || (key === 'weightUnitsPart2' && isInt(input, {gte: 0}))) {
			this.setState({
				[key]: input,
				errors: {
					[key]: null
				}
			});
			return;
		}
		this.setState({
			[key]: input,
			errors: {
				[key]: 'Invalid measurement provided.'
			}
		});
	};

	handleDateChange = e => {
		const valid = isBefore(e.target.value, moment.utc().format('YYYY-MM-DD'));
		let { guardianNameReq } = this.state;
		if (!valid) {
			this.setState({
				errors: {
					dob: 'Invalid date.'
				}
			});
			return;
		}
		const age = moment.utc().diff(e.target.value, 'years');
		if (age >= 18) {
			guardianNameReq = false;
		} else {
			guardianNameReq = true;
		}
		this.setState({
			dob: e.target.value,
			errors: {
				dob: null
			},
			guardianNameReq
		});
	};

	render() {
		const { exists, classes, uuid, requesting, feetOptions, inchOptions } = this.props;
		const {
			password,
			confirmPassword,
			gender,
			dob,
			givenName,
			familyName,
			guardianName,
			heightUnitsPart1,
			heightUnitsPart2,
			weightUnitsPart1,
			weightUnitsPart2,
			errors,
			guardianNameReq
		} = this.state;

		const open = Boolean(!exists && uuid);

		return (
			<Dialog fullScreen open={open} onClose={this.handleOnClose}>
				<DialogTitle id="form-dialog-title">New Patient</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Records associated with this patient have not been found. Enter new
						patient details or click Cancel to search again.
					</DialogContentText>
					<TextField
						autoFocus
						autoComplete="off"
						onChange={this.handleOnChange('givenName')}
						value={givenName}
						id="givenName"
						label="Given Name"
						margin="dense"
						required
						fullWidth
						type="text"
					/>
					<TextField
						autoComplete="off"
						onChange={this.handleOnChange('familyName')}
						value={familyName}
						type="text"
						margin="dense"
						id="familyName"
						label="Family Name"
						required
						fullWidth
					/>
					<FormControl margin="dense" fullWidth>
						<InputLabel htmlFor="gender-native-simple">Gender</InputLabel>
						<Select
							native
							value={gender}
							onChange={this.handleOnChange('gender')}
							inputProps={{ name: 'gender', id: 'gender-native-simple' }}
						>
							<option value="U">Unspecified</option>
							<option value="M">Male</option>
							<option value="F">Female</option>
							<option value="O">Other</option>
						</Select>
					</FormControl>

					<TextField
						onChange={this.handleDateChange}
						value={dob}
						error={!!errors.dob}
						helperText={errors.dob}
						type="date"
						margin="dense"
						id="dob"
						label="Date of Birth"
						required
						fullWidth
					/>
					<div>
						{/*
							FIXME: The following currently assumes imperial measurement
							settings as the provider's preference. This will be later
							changed to ask for the provider's preference and store the
							same in the database. Changes will then be made to the client
							to display appropriate fields.
						*/}
						<FormControl required className={classnames(classes.width, classes.margin)}>
							<InputLabel htmlFor="heightUnitsPart1">Height(ft)</InputLabel>
							<Select
								native
								value={heightUnitsPart1}
								onChange={this.handleOnChange('heightUnitsPart1')}
								inputProps={{ name: 'Height', id: 'heightUnitsPart1' }}
							>
								<option value="" />
								{feetOptions.map(opt => {
									return <option key={opt} value={opt}>{opt}</option>;
								})}
							</Select>
							<FormHelperText>Required</FormHelperText>
						</FormControl>
						<FormControl className={classnames(classes.width, classes.margin)}>
							<InputLabel htmlFor="heightUnitsPart1">Height(in)</InputLabel>
							<Select
								native
								value={heightUnitsPart2}
								onChange={this.handleOnChange('heightUnitsPart2')}
								inputProps={{ name: 'Height', id: 'heightUnitsPart2' }}
							>
								<option key="inchOption" value="0">0</option>
								{inchOptions.map(opt => {
									return <option key={opt} value={opt}>{opt}</option>;
								})}
							</Select>
						</FormControl>
					</div>
					<div>
						<TextField
							autoComplete="off"
							className={classnames(classes.width, classes.margin)}
							onChange={this.handleMeasurementChange('weightUnitsPart1')}
							value={weightUnitsPart1}
							type="number"
							margin="dense"
							id="weightUnitsPart1"
							error={!!errors.weightUnitsPart1}
							helperText={errors.weightUnitsPart1}
							label="Weight"
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">lbs</InputAdornment>
								)
							}}
							required
						/>
						<TextField
							autoComplete="off"
							className={classnames(classes.width, classes.margin)}
							onChange={this.handleMeasurementChange('weightUnitsPart2')}
							value={weightUnitsPart2}
							type="number"
							margin="dense"
							id="weightUnitsPart2"
							error={!!errors.weightUnitsPart2}
							helperText={errors.weightUnitsPart2}
							label="Weight"
							InputProps={{
								endAdornment: <InputAdornment position="end">oz</InputAdornment>
							}}
						/>
					</div>
					<TextField
						onChange={this.handleOnChange('guardianName')}
						value={guardianName}
						type="text"
						margin="dense"
						id="guardianName"
						label="Guardian Name"
						required={guardianNameReq}
						fullWidth
					/>
					<TextField
						onChange={this.handleOnChange('password')}
						value={password}
						type="password"
						margin="dense"
						required
						id="password"
						label="Password"
						minLength={6}
						fullWidth
					/>
					<TextField
						onChange={this.handleOnChange('confirmPassword')}
						value={confirmPassword}
						error={Boolean(
							password.length &&
								confirmPassword.length &&
								password !== confirmPassword
						)}
						type="password"
						margin="dense"
						required
						id="confirm_password"
						label="Confirm Password"
						minLength={6}
						fullWidth
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleOnClose} color="primary">
						Cancel
					</Button>
					<Button
						onClick={this.handleOnSubmit}
						variant="contained"
						color="primary"
						disabled={this.disabled || requesting}
					>
						{requesting ? (
							<CircularProgress style={{ color: '#FFF' }} size={24} />
						) : (
							'Submit'
						)}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	exists: getExists,
	uuid: getUuid,
	requesting: getRequesting
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			createPatient,
			clearFind
		},
		dispatch
	);

export default compose(
	withStyles(styles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(PatientCreateDialog);
