import { errorHandler } from 'modules/common';
import { api } from 'utils/request';
import store from 'utils/store';
import get from 'lodash.get';
import { push } from 'connected-react-router';

import { SESSION_SUCCESS } from 'modules/session';
import { getCountryCode, getPhoneNumber } from 'modules/authentication/code';

/*
 * Actions
 */
const reducerName = 'authentication';
export const AUTH_REQUESTING = `${reducerName}/AUTH_REQUESTING`;
export const AUTH_SUCCESS = `${reducerName}/AUTH_SUCCESS`;
export const AUTH_FAILURE = `${reducerName}/AUTH_FAILURE`;
export const AUTH_CLEAR = `${reducerName}/AUTH_CLEAR`;
export const DISPLAY_NAME_CLEAR = `${reducerName}/DISPLAY_NAME_CLEAR`;

/*
 * Reducer
 */
const initialState = {
	requesting: false,
	token: null,
	userUuid: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case AUTH_REQUESTING:
			return {...state, requesting:true};

		case SESSION_SUCCESS:
		case AUTH_SUCCESS:
			store.setItem('at', action.token);
			store.setItem('provUuid', action.userUuid);
			return {...state,	requesting:false,	token:action.token,	userUuid:action.userUuid};

		case AUTH_FAILURE:
			return {...initialState, error:action.error};

		case AUTH_CLEAR:
			return {...initialState};

		case DISPLAY_NAME_CLEAR:
			return {...initialState};

		default:
			return state;
	}
};

/*
 * Action Creators
 */
export const requestCodeAuthentication = code => (dispatch, getState) => {
	const state = getState();

	const phoneNumber = getPhoneNumber(state);
	const countryCode = getCountryCode(state);

	if (!phoneNumber || !countryCode) {
		return;
	}

	dispatch({ type: AUTH_REQUESTING });
	// const sanitizedPhNumber = phoneNumber.replace(/ /g, '');
	api({method:'POST', url:'/api/authentication/verify-code',
		data: {countryCode, phoneNumber, verifCode: code}
	})
	.then(response => {
		dispatch({
			type: AUTH_SUCCESS,
			token: get(response, 'data.token'),
			userUuid: get(response, 'data.userUuid')
		});
	})
	.catch(error => {
		dispatch({type: AUTH_FAILURE, error});
		dispatch(errorHandler(error));
	});
};

export const deauthenticate = () => dispatch => {
	store.removeItem('at');
	store.removeItem('provUuid');

	dispatch({type: AUTH_CLEAR});
	dispatch(push('/'));
};

export const clearDisplayName = () => ({
	type: DISPLAY_NAME_CLEAR
});

/*
 * Selectors
 */
export const getRequesting = state => get(state[reducerName], 'requesting');
export const getToken = state => get(state[reducerName], 'token');
