import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { history } from 'store';
import Typography from '@material-ui/core/Typography';

const styles = theme => {
	return {
		logo: {
			backgroundImage: 'url("/assets/icon-green.png")',
			width: theme.typography.display1.fontSize,
			height: theme.typography.display1.fontSize,
			display: 'inline-block',
			backgroundPosition: 'center',
			backgroundSize: 'cover',
			position: 'relative',
			top: 3,
			right: 4,
			marginRight: 2
		},
		headline: {
			textAlign: 'left',
			paddingTop: theme.spacing.unit
		}
	};
};

const Logo = props => {
	return (
		<Typography
			color="inherit"
			className={props.classes.headline}
			variant="display1"
		>
			<div
				onClick={() => {
					history.push('/');
				}}
			>
				<div className={props.classes.logo} />
				Recare
			</div>
		</Typography>
	);
};

export default withStyles(styles)(Logo);
