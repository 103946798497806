import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import moment from 'moment';

import EncounterCard from './EncounterCard';

const styles = theme => ({
	horizontal: {
		paddingLeft: theme.spacing.unit * 2,
		paddingRight: theme.spacing.unit * 2,
		whiteSpace: 'nowrap',
		flex: '0 0 auto',
		overflowX: 'scroll'
	}
});

class EncounterBlock extends Component {
	get date() {
		return moment.utc(this.props.date).format('ll');
	}

	render() {
		const { classes, info } = this.props;

		return (
			<Fragment>
				<ListItem style={{
					flexWrap: 'nowrap',
					overflowX: 'auto',
				}}>
					<ListItemText
						primary={this.date}
						secondary={`${this.props.info.length} Notes`}
					/>
				</ListItem>
				<div className={classes.horizontal}>
					{info.map(i => {
						return <EncounterCard key={i.uuid} {...i} />;
					})}
				</div>
			</Fragment>
		);
	}
}

EncounterBlock.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default compose(
	withStyles(styles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(EncounterBlock);
