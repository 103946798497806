import Cookies from 'js-cookie';

export default {
  removeItem: (key) => {
    if (window.localStorage) {
      window.localStorage.removeItem(key);
    }
    Cookies.set(key, null);
  },

  setItem: (key, value) => {
    if (window.localStorage) {
      window.localStorage.setItem(key, value);
    }
    Cookies.set(key, value);
  },

  getItem: (key) => {
    if (window.localStorage) {
      return window.localStorage.getItem(key);
    }
    return Cookies.get(key);
  }
};
