import { errorHandler } from 'modules/common';
import get from 'lodash.get';
import AWS from 'aws-sdk';

/*
 * Actions
 */
const reducerName = 'aws';
export const AWS_INIT = `${reducerName}/AWS_INIT`;
export const AWS_INIT_SUCCESS = `${reducerName}/AWS_INIT_SUCCESS`;
export const AWS_INIT_FAILURE = `${reducerName}/AWS_INIT_FAILURE`;
export const AWS_CLEAR = `${reducerName}/AWS_CLEAR`;

/*
 * Reducer
 */
const initialState = {
	requesting: false,
	instance: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case AWS_INIT:
			return {
				...state,
				requesting: true
			};

		case AWS_INIT_SUCCESS:
			return {
				...state,
				requesting: false,
				instance: action.instance
			};

		case AWS_INIT_FAILURE:
			return {
				...state,
				requesting: false
			};

		case AWS_CLEAR:
			return {
				...initialState
			};

		default:
			return state;
	}
};

/*
 * Action Creators
 */
export const initialize = () => dispatch => {
	dispatch({
		type: AWS_INIT
	});

	AWS.config.region = 'us-west-2';
	AWS.config.credentials = new AWS.CognitoIdentityCredentials(
		{
			IdentityPoolId: 'us-west-2:069eeb17-50ab-4fd1-95d7-47ec909ac8c4'
		},
		{
			region: AWS.config.region
		}
	);
	AWS.config.logger = console;

	AWS.config.credentials.get(error => {
		if (error) {
			dispatch({
				type: AWS_INIT_FAILURE,
				error
			});
			dispatch(errorHandler(error));
			return;
		}

		const instance = new AWS.S3({
			apiVersion: '2006-03-01',
			params: {
				Bucket: 'recare-img-inp-test1-lvtv',
				credentials: AWS.config.credentials
			}
		});

		dispatch({
			type: AWS_INIT_SUCCESS,
			instance
		});
	});
};

export const clearSession = () => ({
	type: AWS_CLEAR
});

/*
 * Selectors
 */
export const getInstance = state => get(state[reducerName], 'instance');
export const getRequesting = state => get(state[reducerName], 'requesting');
