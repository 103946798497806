import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	action: {
		display: 'inline',
		marginLeft: theme.spacing.unit
	}
});

class Action extends React.PureComponent {
	render() {
		const { classes } = this.props;

		return <div className={classes.action}>{this.props.children}</div>;
	}
}

export default withStyles(styles)(Action);
