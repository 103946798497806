import React, { Component } from 'react';

// Redux
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getSuccess } from 'modules/authentication/code';

// UI
import withFull from 'layouts/withFull';
import CodeForm from './CodeForm';
import PhoneNumberForm from './PhoneNumberForm';

class Entry extends Component {
	render() {
		const { codeSent } = this.props;

		return <div>{codeSent ? <CodeForm /> : <PhoneNumberForm />}</div>;
	}
}

const mapStateToProps = createStructuredSelector({
	codeSent: getSuccess
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default compose(
	withFull,
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(Entry);
