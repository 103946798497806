import get from 'lodash.get';
import store from 'utils/store';
import request from 'utils/request';
import { errorHandler } from 'modules/common';

/*
 * Actions
 */
const reducerName = 'appVersion';
export const APP_VERSION_SUCCESS = `${reducerName}/APP_VERSION_SUCCESS`;
export const APP_VERSION_FAILURE = `${reducerName}/APP_VERSION_FAILURE`;

/*
 * Reducer
 */
const initialState = {
	requesting: true,
	success: false,
	appVersion: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case APP_VERSION_SUCCESS:
			// av => app-version
			store.setItem('av', action.appVersion);
			return {
				...state,
				requesting: false,
				success: true,
				appVersion: action.appVersion
			};

		case APP_VERSION_FAILURE:
			return {
				...state,
				requesting: false,
				error: action.error
			};

		default:
			return state;
	}
};

/*
 * Action Creators
 */
export const requestAppVersion = () => dispatch => {
	request('/ver.json')
		.then(response => {
			dispatch({
				type: APP_VERSION_SUCCESS,
				appVersion: get(response, 'json.version')
			});
		})
		.catch(error => {
			dispatch({
				type: APP_VERSION_FAILURE,
				error
			});
			dispatch(errorHandler(error));
		});
};

export const getAppVersion = state => get(state[reducerName], 'appVersion');
export const getAppVersionFromLocalStorage = () => {
	return store.getItem('av');
};
