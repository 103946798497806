import React, { PureComponent } from 'react';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	image: {
		width: '100%',
		imageOrientation: 'from-image'
	}
});

class Image extends PureComponent {
	render() {
		const { classes } = this.props;

		return <img alt="Note" className={classes.image} src={this.props.url} />;
	}
}

export default withStyles(styles)(Image);
