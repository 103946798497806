import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import {
	requestMedia,
	captureMedia,
	registerRefs,
	getReady,
	mediaCanPlay,
	clearMedia,
	getFacingMode
} from 'modules/media';
import { createStructuredSelector } from 'reselect';

// UI
import Fab from '@material-ui/core/Fab';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
//import CameraFrontIcon from '@material-ui/icons/CameraFront';
//import CameraRearIcon from '@material-ui/icons/CameraRear';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	root: {
		position:'fixed', top:0, bottom:0, right:0, left:0,
		zIndex: 9999,
		background: '#000',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column'
	},
	hide: {
		display: 'none'
	},
	video: {
		maxWidth: '100%'
	},
	fab: {
		position: 'absolute',
		bottom: theme.spacing.unit * 2,
		left: 0,
		right: 0,
		margin: '0 auto'
	},
	switchCamera: {
		background: 'rgba(255,255,255,.15)',
		'&:hover': {
			background: 'rgba(255,255,255,.25)'
		},
		position: 'absolute',
		bottom: theme.spacing.unit * 2.5,
		right: theme.spacing.unit * 2,
		color: '#FFF'
	},
	close: {
		background: 'rgba(255,255,255,.15)',
		'&:hover': {
			background: 'rgba(255,255,255,.25)'
		},
		position: 'absolute',
		bottom: theme.spacing.unit * 2.5,
		left: theme.spacing.unit * 2,
		color: '#FFF'
	}
});

class MediaCapture extends Component {
	constructor(props) {
		super(props);

		this.videoRef = React.createRef();
		this.canvasRef = React.createRef();

		props.registerRefs(this.videoRef, this.canvasRef);
	}

	componentDidMount() {
		this.videoRef.current.addEventListener('canplay', this.handleCanPlay);
	}

	componentWillUnmount() {
		this.videoRef.current.removeEventListener('canplay', this.handleCanPlay);
		this.props.clearMedia();
	}

	handleCanPlay = () => {
		this.props.mediaCanPlay();
	};

	handleCapture = () => {
		this.props.captureMedia();
	};

	handleClose = () => {
		this.props.clearMedia();
	};

	render() {
		const { classes, ready } = this.props;

		const rootClass = ready ? classes.root : classes.hide;

		// console.error({f:'MediaCapturejs', facingMode});
		return (
			<Slide in={ready} direction="up">
				<div className={rootClass}>
					<video ref={this.videoRef} className={classes.video} playsInline />
					<canvas ref={this.canvasRef} className={classes.hide} />
					<Fab color="primary" className={classes.fab} onClick={this.handleCapture}>
						<PhotoCameraIcon />
					</Fab>
					<IconButton className={classes.close} onClick={this.handleClose}>
						<CloseIcon />
					</IconButton>
					
				</div>
			</Slide>
		);
	}
}

MediaCapture.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = createStructuredSelector({
	ready: getReady,
	facingMode: getFacingMode
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			registerRefs,
			requestMedia,
			captureMedia,
			mediaCanPlay,
			clearMedia
		},
		dispatch
	);

export default compose(
	withStyles(styles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(MediaCapture);
