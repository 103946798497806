import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

import isBefore from 'validator/lib/isBefore';
import moment from 'moment';
import { EditorState } from 'draft-js-android-fix';
import { Editor } from '@livelyvideo/react-draft-wysiwyg';
import { stateToHTML } from '@livelyvideo/draft-js-export-html';
import '@livelyvideo/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import toolbarOptions from 'utils/toolbar-options';
import Upload from 'components/Upload';

const styles = theme => ({});

const INITIAL_STATE = {
	editorState: EditorState.createEmpty(),
	errors: {},
	date: moment.utc().format('YYYY-MM-DD'),
	dateError: null,
	uploading: false,
	pendingSubmit: false,
	uploadUrl: null,
	uploadId: null,
	inCompositionMode: false
};

class AddEncounter extends Component {
	state = Object.assign({}, INITIAL_STATE);

	onEditorStateChange = editorState => {
		this.setState({editorState, inCompositionMode: editorState.isInCompositionMode()});
	};

	handleChange(field) {
		return e => {
			this.setState({[field]:e.target.value});
		};
	}

	handleDateChange = e => {
		const valid = isBefore(e.target.value, moment.utc().format('YYYY-MM-DD'));

		if (!valid) {
			this.setState({dateError: 'Invalid date.'});
			return;
		}

		this.setState({
			date: e.target.value,
			dateError: null
		});
	};

	componentWillReceiveProps(nextProps) {
		if (!this.props.saved && nextProps.saved) {
			this.setState(Object.assign({}, INITIAL_STATE));
		}
	}

	onSubmit = () => {
		if (this.state.uploading) {
			this.setState({pendingSubmit: true});
			return;
		}
		// Delay the form submission so that the editor text is captured and correct.
		setTimeout(() => {
			this.props.onSubmit({
				content: stateToHTML(this.state.editorState.getCurrentContent()),
				date: this.state.date,
				uploadUrl: this.state.uploadUrl,
				uploadId: this.state.uploadId
			});
			this.setState({pendingSubmit: false});
		}, 20);
	};

	handleOnStart = () => {
		this.setState({uploading: true});
	};

	handleOnFailure = () => {
		this.setState({uploading: false});
	};

	handleOnSuccess = (file, uploadUrl, uploadId) => {
		this.setState({uploading:false, uploadUrl, uploadId});
	};

	get disabled() {
		const { editorState, uploadId, uploading, inCompositionMode } = this.state;

		if (uploading) {
			return true;
		}

		const emptyEditor =
			stateToHTML(editorState.getCurrentContent()) === `<p><br></p>` && !inCompositionMode;

		return emptyEditor && !uploadId;
	}

	render() {
		const { requesting } = this.props;
		const { date, dateError, editorState } = this.state;

		return (
			<Dialog
				fullScreen
				open={this.props.open}
				onClose={this.props.onDismiss}
				aria-labelledby="form-dialog-title"
				fullWidth
			>
				<DialogTitle id="form-dialog-title">Add Visit Information</DialogTitle>
				<DialogContent>
					<TextField required fullWidth
						onChange={this.handleDateChange}
						error={!!dateError}
						helperText={dateError}
						value={date} type="date"
						margin="dense"
						id="date" label="Date"
					/>

					<Upload
						onStart={this.handleOnStart}
						onSuccess={this.handleOnSuccess}
						onFailure={this.handleOnFailure}
					/>

					<Editor
						editorState={editorState}
						toolbar={toolbarOptions}
						onEditorStateChange={this.onEditorStateChange}
						editorStyle={{
							borderWidth: 1,
							borderStyle: 'solid',
							borderColor: '#ccc',
							minHeight: '160px',
							paddingLeft: '16px',
							paddingRight: '16px'
						}}
						placeHolder="Notes"
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.props.onDismiss} color="primary">
						Cancel
					</Button>
					<Button
						onClick={this.onSubmit}
						color="primary"
						disabled={this.disabled || requesting}
						variant="contained"
					>
						{requesting ? (
							<CircularProgress style={{ color: '#FFF' }} size={24} />
						) : (
							'Save'
						)}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

AddEncounter.propTypes = {
	classes: PropTypes.object.isRequired,
	onDismiss: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	saved: state.encounters.saved,
	requesting: state.encounters.saving
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default compose(
	withStyles(styles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(AddEncounter);
