import errorToReasons from 'utils/error-to-reasons';
import request from 'utils/request';
import get from 'lodash.get';
import { errorHandler } from 'modules/common';
import store from 'utils/store';

/*
 * Actions
 */
const reducerName = 'encounters';
export const ENCOUNTERS = `${reducerName}/ENCOUNTERS`;
export const ENCOUNTER_CLEAR = `${reducerName}/ENCOUNTERS_CLEAR`;
export const ENCOUNTER_CLEAR_SAVE = `${reducerName}/ENCOUNTERS_CLEAR_SAVE`;
export const ENCOUNTERS_FAILED = `${reducerName}/ENCOUNTERS_FAILED`;
export const ENCOUNTERS_REQUESTED = `${reducerName}/ENCOUNTERS_REQUESTED`;
export const ENCOUNTERS_SAVED = `${reducerName}/ENCOUNTERS_SAVED`;
export const ENCOUNTERS_SAVING = `${reducerName}/ENCOUNTERS_SAVING`;
export const ENCOUNTERS_SAVE_FAILED = `${reducerName}/ENCOUNTERS_SAVE_FAILED`;

/*
 * Reducer
 */
const initialState = {
	encounters: [],
	patientUuid: null,
	reasons: [],
	requesting: false,
	saving: false,
	saved: false,
	saveReasons: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ENCOUNTERS:
			return {
				...state,
				encounters: action.encounters,
				patientUuid: action.patientUuid,
				reasons: [],
				requesting: false
			};
		case ENCOUNTER_CLEAR_SAVE:
			return {
				...state,
				saving: false
			};
		case ENCOUNTER_CLEAR:
			return Object.assign({}, initialState);
		case ENCOUNTERS_FAILED:
			return {
				...state,
				requesting: false,
				reasons: action.reasons
			};
		case ENCOUNTERS_REQUESTED:
			return {
				...state,
				requesting: true,
				reasons: []
			};
		case ENCOUNTERS_SAVED:
			const encounters = Object.assign([], state.encounters);

			let pushed = false;
			for (let i = 0; i < encounters.length; i++) {
				if (encounters[i].date === action.encounter.date) {
					encounters[i].info.unshift(action.encounter);
					pushed = true;
				} else if (action.encounter.date > encounters[i].date) {
					encounters.splice(i, 0, {
						date: action.encounter.date,
						info: [action.encounter]
					});
					pushed = true;
				}
				if (pushed) {
					break;
				}
			}

			if (!pushed) {
				encounters.push({
					date: action.encounter.date,
					info: [action.encounter]
				});
			}

			return {
				...state,
				encounters,
				saving: false,
				saved: true,
				saveReasons: []
			};
		case ENCOUNTERS_SAVING:
			return {
				...state,
				saving: true,
				saved: false,
				saveReasons: []
			};
		case ENCOUNTERS_SAVE_FAILED:
			return {
				...state,
				saving: false,
				saved: false,
				saveReasons: action.reasons
			};
		default:
			return state;
	}
};

/*
 * Action Creators
 */
export const clearEncounterSave = () => ({
	type: ENCOUNTER_CLEAR_SAVE
});

export const postEncounterInfo = (patientUuid, data) => dispatch => {
	dispatch({
		type: ENCOUNTERS_SAVING
	});
	const regex = /&nbsp;/gi;
	data.content = data.content.replace(regex, ' ');

	request(`/api/encounters/patient/${patientUuid}/info`, {
		method: 'POST',
		body: {
			content: data.content,
			date: data.date,
			uploadId: data.uploadId,
			uploadUrl: data.uploadUrl,
			provUuid: store.getItem('provUuid')
		}
	})
	.then(response => {
		dispatch({
			type: ENCOUNTERS_SAVED,
			encounter: response.json
		});
	})
	.catch(err => {
		dispatch({
			type: ENCOUNTERS_SAVE_FAILED,
			reasons: errorToReasons(err)
		});
		dispatch(errorHandler({response: {data: err.json, status: err.status}}));
	});
};

export const getEncounterInfo = patientUuid => dispatch => {
	dispatch({
		type: ENCOUNTERS_REQUESTED
	});

	request(`/api/encounters/patient/${patientUuid}`, {
		method: 'GET'
	})
		.then(response => {
			dispatch({
				type: ENCOUNTERS,
				encounters: response.json.encounters
			});
		})
		.catch(err => {
			dispatch({
				type: ENCOUNTERS_FAILED,
				reasons: errorToReasons(err)
			});
		});
};

/*
 * Reducer
 */
export const getRequesting = state => get(state[reducerName], 'requesting');
