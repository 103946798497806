import get from 'lodash.get';
import { addSnack } from 'modules/snackbar';
import { deauthenticate } from 'modules/authentication';

/*
 * Action Creators
 */
export const errorHandler = error => (dispatch, getState) => {
	const { response } = error;

	if (!response || !response.status) {
		dispatch(
			addSnack({
				message: error.message || 'Could not connect to server.'
			})
		);
		return;
	}

	if (response.status === 401) {
		dispatch(deauthenticate());
		return;
	}

	if (response.status < 500) {
		dispatch(
			addSnack({
				message: get(response, 'data.reasons[0].text', 'Unsupported request.')
			})
		);
		return;
	}

	if (response.status >= 500) {
		dispatch(
			addSnack({
				message: get(response, 'data.reasons[0].text', 'Internal server error.')
			})
		);
		return;
	}

	console.error('Unhandled response');
};
