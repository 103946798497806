import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { history } from 'store';
import { accessPatient, clearPatient } from 'modules/patients/patient';

// UI
import { withStyles } from '@material-ui/core/styles';
import Header from 'containers/Header';
import Viewport from 'components/Viewport';
import AccessForm from './AccessForm';
import Encounters from './Encounters';
import PatientHeader from './Header';

const styles = theme => ({
	content: {
		transition: theme.transitions.create('margin-top')
	},
	contentExpanded: {
		marginTop: theme.spacing.unit * 19
	}
});

class Patient extends Component {
	render() {
		const { classes, patient } = this.props;

		if (!patient) {
			return (
				<AccessForm
					uuid={this.props.match.params.uuid}
					onCancel={() => {
						this.props.clearPatient();
						history.push(`/patients/find`);
					}}
				/>
			);
		}

		return (
			<Viewport>
				<Header
					back
					title={`${patient.familyName}, ${patient.givenName}`}
					expanded={false}
				>
					<PatientHeader />
				</Header>
				<div className={classes.content}>
					<Encounters />
				</div>
			</Viewport>
		);
	}
}

Patient.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	patient: state.patient.patient,
	reasons: state.patient.reasons,
	requesting: state.patient.requesting,
	uuid: state.patient.uuid
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			accessPatient,
			clearPatient
			// createPatient,
			// findPatient,
		},
		dispatch
	);

export default compose(
	withStyles(styles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(Patient);
