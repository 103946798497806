import get from 'lodash.get';

/*
 * Actions
 */
const reducerName = 'snackbar';
export const SNACKBAR_ADD = `${reducerName}/SNACKBAR_ADD`;
export const SNACKBAR_CLEAR = `${reducerName}/SNACKBAR_CLEAR`;

/*
 * Reducer
 */
const initialState = {
	open: false,
	snack: {}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SNACKBAR_ADD:
			return {
				...state,
				snack: action.snack,
				open: true
			};

		case SNACKBAR_CLEAR:
			return {
				...initialState
			};

		default:
			return state;
	}
};

/*
 * Action Creators
 */
export const addSnack = snack => {
	const { message } = snack;

	if (!message) {
		console.warn('addSnack: Requires message field');
		return;
	}

	return {
		type: SNACKBAR_ADD,
		snack
	};
};

export const clearSnack = () => ({
	type: SNACKBAR_CLEAR
});

/*
 * Selectors
 */
export const getOpen = state => get(state[reducerName], 'open');
export const getSnack = state => get(state[reducerName], 'snack');
