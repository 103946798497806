import React from 'react';
import LivelyPlayer from '@livelyvideo/stock-vod-player/dist/stock-vod-player';
import '@livelyvideo/stock-vod-player/dist/stock.min.css';

export default class Player extends React.PureComponent {
	constructor() {
		super();

		this.playerRef = React.createRef();
		this.livelyPlayer = null;
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.stop && !this.props.stop && this.livelyPlayer) {
			this.livelyPlayer.player.stop();
		}
	}

	get pathprefix() {
		return 'assets';
	}

	componentDidMount() {
		if (this.playerRef && this.livelyPlayer) {
			return;
		}

		const posterURI = this.props.posterURI
			? this.props.posterURI.replace('%s', 'X_460')
			: false;
		// debugger;
		this.livelyPlayer = new LivelyPlayer(
			this.playerRef.current,
			this.props.manifest,
			{
				autoplay: !!this.props.autoplay,
				drivers: ['hls', 'hlsjs', 'nativeMp4'],
				hlsjsPath: 'https://cdn.jsdelivr.net/npm/hls.js@latest', // `${this.pathprefix}/hls.min.js`,
				hijackingClicks: false,
				writeLogsToConsole: true,
				posterURI
			},
			{
				messages: {
					'Stream is currently offline': 'Media is not available'
				}
			}
		);

		this.livelyPlayer.on('error', () => {
			console.log('err');
		});

		this.livelyPlayer.player.on('timeupdate', args => {
			if (this.props.ontimeupdate) {
				this.props.ontimeupdate(this.livelyPlayer.player);
			}
		});
	}

	componentWillUnmount() {
		if (!this.livelyPlayer) {
			return;
		}

		this.livelyPlayer = null;
	}

	render() {
		return (
			<div ref={this.playerRef} style={{ margin: '0' }} className="lvvideo" />
		);
	}
}
