import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

import createRootReducer from 'modules';

const logger = createLogger({
	collapsed: true
});

export const history = createBrowserHistory();

const initialState = {};
const middleware = [thunk, routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') {
	middleware.push(logger);
}

const store = createStore(
	createRootReducer(history),
	initialState,
	compose(applyMiddleware(...middleware))
);

export default store;
