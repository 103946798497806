import React from 'react';

const SVGMan = props => (
	<svg
		viewBox="0 0 500 500"
		fillRule="evenodd"
		clipRule="evenodd"
		strokeLinejoin="round"
		strokeMiterlimit={1.414}
		{...props}
	>
		<path
			d="M104.1 500h291.798c0-47.421-38.388-85.809-85.808-85.809H189.908c-47.42 0-85.808 38.388-85.808 85.808z"
			fill="#bdbdbd"
			fillRule="nonzero"
		/>
		<g transform="translate(-544.479 -138.293) scale(2.50901)">
			<circle cx={362.1} cy={157.3} r={12.7} fill="#eee" />
			<circle cx={362.1} cy={157.3} r={7.5} fill="#e0e0e0" />
		</g>
		<g transform="translate(-544.479 -138.293) scale(2.50901)">
			<circle cx={271.2} cy={157.3} r={12.7} fill="#eee" />
			<circle cx={271.2} cy={157.3} r={7.5} fill="#e0e0e0" />
		</g>
		<path
			d="M249.874 464.873c25.34 0 45.664-20.574 45.664-45.664v-5.018h-91.58v5.018c.252 25.09 20.825 45.664 45.916 45.664z"
			fill="#9e9e9e"
			fillRule="nonzero"
		/>
		<path
			d="M220.267 322.11v97.099c0 16.309 13.298 29.857 29.857 29.857 16.309 0 29.858-13.298 29.858-29.857V322.11h-59.715z"
			fill="#eee"
			fillRule="nonzero"
		/>
		<path
			d="M220.267 370.534c0 16.309 13.047 30.108 29.356 30.36h.501c16.309 0 29.356-13.8 29.356-30.36v-39.14h-59.464v39.14h.251z"
			fill="#e0e0e0"
			fillRule="nonzero"
		/>
		<path
			d="M146.502 169.06v101.867c0 56.954 46.166 103.37 103.372 103.37 56.954 0 103.37-46.165 103.37-103.37V169.06c0-38.388-31.11-69.5-69.499-69.5h-67.743c-38.388 0-69.5 31.112-69.5 69.5z"
			fill="#eee"
			fillRule="nonzero"
		/>
		<g fill="#616161" transform="translate(-544.479 -138.293) scale(2.50901)">
			<circle cx={294.5} cy={144.6} r={4.9} />
			<circle cx={338.7} cy={144.6} r={4.9} />
		</g>
		<path
			d="M212.49 280.712v3.763c0 20.072 16.81 37.134 37.384 37.384 20.573-.25 37.384-17.312 37.384-37.384v-3.763c-24.588-3.764-49.93-3.764-74.769 0z"
			fill="#fff"
			fillRule="nonzero"
		/>
		<path
			d="M361.023 140.709c-17.563-59.965-79.285-73.514-110.899-73.514-31.613 0-93.586 13.298-111.149 73.514-17.563 60.216 7.778 85.808 7.778 85.808s29.105-32.366 29.105-87.063c0 0 31.111 8.03 74.266 8.03s74.016-8.03 74.016-8.03c0 54.697 29.105 87.063 29.105 87.063s25.34-25.843 7.778-85.808z"
			fill="#616161"
			fillRule="nonzero"
		/>
	</svg>
);

export default SVGMan;
