import get from 'lodash.get';
import { api } from 'utils/request';
import { errorHandler } from 'modules/common';
import { createSelector } from 'reselect';
import { AUTH_SUCCESS } from 'modules/authentication';

/*
 * Actions
 */
const reducerName = 'code';
export const CODE_REQUESTING = `${reducerName}/CODE_REQUESTING`;
export const CODE_SUCCESS = `${reducerName}/CODE_SUCCESS`;
export const CODE_FAILURE = `${reducerName}/CODE_FAILURE`;
export const CODE_CLEAR = `${reducerName}/CODE_CLEAR`;
export const DISPLAY_NAME_CLEAR = `${reducerName}/DISPLAY_NAME_CLEAR`;

/*
 * Reducer
 */
const initialState = {
	requesting: false,
	countryCode: false,
	phoneNumber: false,
	error: null,
	newUser: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case CODE_REQUESTING:
			return {...state, requesting:true};

		case CODE_SUCCESS:
			return {...state, requesting:false, countryCode:action.countryCode, phoneNumber:action.phoneNumber, newUser:action.newUser};

		case CODE_FAILURE:
			return {...initialState, newUser:action.newUser, phoneNumber:action.phoneNumber, error:action.error};

		case AUTH_SUCCESS:
		case CODE_CLEAR:
			return {...initialState};
		case DISPLAY_NAME_CLEAR:
			return {...initialState};

		default:
			return state;
	}
};

/*
 * Action Creators
 */
export const requestCode = phoneNumber => dispatch => {
	dispatch({type:CODE_REQUESTING});

	/*
	 * Given string "+1 (800) 500 6666"
	 * returns 1
	 */
	const countryCode = parseInt(
		phoneNumber.substring(
			phoneNumber.indexOf('+')+1,
			phoneNumber.indexOf(' ')
		));
	// const sanitizedPhNumber = phoneNumber.replace(/ /g, '');
	api({method:'POST',  url:'/api/authentication/create-code',
		data: {countryCode, phoneNumber}
	})
	.then(response => {
		dispatch({type:CODE_SUCCESS, countryCode, phoneNumber});
	})
	.catch(error => {
		dispatch({type:CODE_FAILURE, error});
		dispatch(errorHandler(error));
	});
};

export const findUser = phoneNumber => dispatch => {
	dispatch({type: CODE_REQUESTING});

	// const sanitizedPhNumber = phoneNumber; // .replace(/ /g, '');
	api({method:'GET', url:`/api/authentication/provider/${phoneNumber}`})
	.then(response => {
		// console.error({f:__filename, response});
		requestCode(phoneNumber)(dispatch);
	})
	.catch(error => {
		// console.error({f:__filename, error});
		if (!error.response || !error.response.status) {error.response = {status:500}}; // for the case when the service is down
		if (error.response.status === 404) {
			dispatch({type:CODE_FAILURE, newUser:true, phoneNumber, error});
		} else {
			dispatch({type:CODE_FAILURE, error});
			dispatch(errorHandler(error));
		}
	});
};

export const clearCode = () => ({
	type: CODE_CLEAR
});

export const clearDisplayName = () => ({
	type: DISPLAY_NAME_CLEAR
});

export const createNewUser = (displayName, phoneNumber) => dispatch => {
	dispatch({
		type: CODE_REQUESTING
	});

	/*
	 * Given string "+1 (800) 500 6666"
	 * returns 1
	 */
	const countryCode = parseInt(
		phoneNumber.substring(phoneNumber.indexOf('+') + 1,	phoneNumber.indexOf(' '))
	);
	// const sanitizedPhNumber = phoneNumber.replace(/ /g, '');
	api({method:'POST',	url:`/api/authentication/provider`,
		data: {tel:phoneNumber, name:displayName}
	})
	.then(response => {
		dispatch({type:CODE_SUCCESS, countryCode, phoneNumber, newUser:false});
	})
	.catch(error => {
		dispatch({type:CODE_FAILURE, error});
		dispatch(errorHandler(error));
	});
};

export const getNewUserPhoneNumber = () => (dispatch, getState) => {
	const state = getState();
	const phoneNumber = getPhoneNumber(state);
	return phoneNumber;
};

/*
 * Selectors
 */
export const getRequesting = state => get(state[reducerName], 'requesting');
export const getCountryCode = state => get(state[reducerName], 'countryCode');
export const getPhoneNumber = state => get(state[reducerName], 'phoneNumber');
export const getNewUser = state => get(state[reducerName], 'newUser');
export const getSuccess = createSelector(getCountryCode, getPhoneNumber,
	(countryCode, phoneNumber) => countryCode && phoneNumber
);