import React, { Component } from 'react';
import Header from 'containers/Header';
import Viewport from 'components/Viewport';

class MainLayout extends Component {
	render() {
		return (
			<Viewport>
				<Header noMargin={true} />
				{this.props.children}
			</Viewport>
		);
	}
}

export default MainLayout;
