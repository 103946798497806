import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import SvgLogo from 'components/logo/svg';
import CloseIcon from '@material-ui/icons/Close';
import Installed from './Installed';

const DISABLE_AD2HS = false;

const tmp = {};
const sessionStorage = window.sessionStorage || {
	setItem: (k, v) => { tmp[k] = v; },
	getItem: k => { return tmp[k]; },
};

const styles = theme => ({
	appBar: {
		top: 'auto',
		bottom: 0
	},
	closeIcon: {
		float: 'right',
		marginTop: theme.spacing.unit * 2
	},
	logo: {
		height: '2.6rem',
		width: '2.6rem',
		marginBottom: '-1.4rem'
	},
	header: {
		fontSize: '1.2rem',
		display: 'inline'
	},
	banner: {
		width: '100%',
		flexDirection: 'column'
	},
	bannerDetails: {
		display: 'inline-block',
		width: 256,
	},
	actions: {
		width: '100%',
		textAlign: 'right',
		paddingTop: theme.spacing.unit * 2
	},
	root: {
		flexDirection: 'column',
		textAlign: 'left',
		minHeight: 64,
		paddingBottom: theme.spacing.unit * 2,
		paddingTop: theme.spacing.unit,
		paddingLeft: theme.spacing.unit * 2,
		paddingRight: theme.spacing.unit * 2,
	},
	action: {
		paddingLeft: theme.spacing.unit * 2,
		paddingRight: theme.spacing.unit * 2
	}
});

class AddToHomeScreen extends Component {
	constructor() {
		super();

		this.state = {
			appInstalled: false,
			close: Boolean(sessionStorage.getItem('pwa:close', true)),
			deferredPrompt: null
		};

		this.beforeInstallPrompt = e => {
			// e.preventDefault();
			// this.setState({
			// 	deferredPrompt: e
			// });
		};

		this.appInstalled = e => {
			this.setState({
				appInstalled: true,
			});
		};
	}
	componentWillMount() {
		window.addEventListener('beforeinstallprompt', this.beforeInstallPrompt);
		window.addEventListener('appinstalled', this.appInstalled);
	}

	componentWillUnmount() {
		window.removeEventListener('beforeinstallprompt', this.beforeInstallPrompt);
		window.removeEventListener('appinstalled', this.appInstalled);
	}

	add = e => {
		if (!this.state.deferredPrompt) {
			return;
		}

		const deferredPrompt = this.state.deferredPrompt;
		deferredPrompt.prompt();
		deferredPrompt.userChoice.then(choiceResult => {
			if (choiceResult.outcome !== 'accepted') {
				this.setState({
					deferredPrompt: null
				});
			}
		});
	}

	render() {
		const { classes } = this.props;

		// In a PWA!
		if (window.matchMedia('(display-mode: standalone)').matches) {
			return null;
		}

		if (this.state.appInstalled) {
			return <Installed onClose={() => {
				this.setState({
					appInstalled: false
				})
			}}/>;
		}

		if (this.state.close || !this.state.deferredPrompt) {
			return null;
		}

		if (DISABLE_AD2HS) {
			return null;
		}

		return <AppBar position="fixed" color="default" className={classes.appBar}>
			<Toolbar className={classes.root}>
				<div className={classes.banner}>
					<CloseIcon className={classes.closeIcon} onClick={() => {
						sessionStorage.setItem('pwa:close', true);
						this.setState({
							close: true
						});
					}} />
					<SvgLogo className={classes.logo} />
					<List className={classes.bannerDetails}>
						<ListItem>
							<ListItemText primary='Recare' secondary='www.recare-app.com' />
						</ListItem>
					</List>
				</div>
				<div className={classes.actions}>
					<Button onClick={this.add} className={classes.action} size='small' variant='contained' color='primary'>
						Add to Home Screen
					</Button>
				</div>
			</Toolbar>
		</AppBar>;
	}
}

export default withStyles(styles)(AddToHomeScreen);
