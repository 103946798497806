import get from 'lodash.get';
import calculateFullAge from 'full-age-calculator';
import { api } from 'utils/request';
import { errorHandler } from 'modules/common';
import {imperialToMetricLength, imperialToMetricWeight} from '@livelyvideo/measurement-conversion';
import { postEncounterInfo, getEncounterInfo } from 'modules/encounters/encounters';

const initialState = {
	requesting: false,
	patient: null,
	passwordError: null
};

/*
 * Actions
 */
const reducerName = 'patient';
export const PATIENT_REQUESTING = `${reducerName}/PATIENT_REQUESTING`;
export const PATIENT_SUCCESS = `${reducerName}/PATIENT_SUCCESS`;
export const PATIENT_FAILURE = `${reducerName}/PATIENT_FAILURE`;
export const PATIENT_CLEAR = `${reducerName}/PATIENT_CLEAR`;

function loadPatient(patient) {
	const dict = { F: 'Female', M: 'Male', O: 'Other' };
	patient.genderHuman = dict[patient.gender] || 'Unspecified';

	const date = calculateFullAge.getFullAge(patient.dob);
	if (date.years) {
		patient.age = `${date.years} Years`;
	} else if (date.months) {
		patient.age = `${date.months} Months`;
	} else {
		patient.age = `${date.days} Days`;
	}
	return patient;
}

/*
 * Reducer
 */
export default (state = initialState, action) => {
	switch (action.type) {
		case PATIENT_REQUESTING:
			return {
				...state,
				requesting: true
			};

		case PATIENT_SUCCESS:
			return {
				...state,
				requesting: false,
				patient: loadPatient(action.patient)
			};

		case PATIENT_FAILURE:
			return {
				...state,
				requesting: false,
				passwordError: 'Invalid password'
			};

		case PATIENT_CLEAR:
			return {
				...initialState
			};

		default:
			return state;
	}
};

/*
 * Actions Creators
 */
export const accessPatient = (uuid, password) => dispatch => {
	dispatch({
		type: PATIENT_REQUESTING
	});

	api({
		method: 'POST',
		url: `/api/patients/patient/${uuid}/token`,
		data: {
			guardianPassword: password
		}
	})
		.then(response => {
			dispatch({
				type: PATIENT_SUCCESS,
				patient: response.data
			});
		})
		.catch(error => {
			dispatch({
				type: PATIENT_FAILURE,
				error
			});
		});
};

export const createPatient = (uuid, data) => dispatch => {
	let height;
	let weight;
	try {
		weight = imperialToMetricWeight(
			parseFloat(data.weightUnitsPart1),
			parseFloat(data.weightUnitsPart2)
		);
		height = imperialToMetricLength(
			parseFloat(data.heightUnitsPart1),
			parseFloat(data.heightUnitsPart2)
		);
	} catch (error) {
		dispatch({
			type: PATIENT_FAILURE,
			error
		});
		dispatch(errorHandler(error));
		return;
	}
	const { password, confirmPassword, ...rest } = data;

	dispatch({
		type: PATIENT_REQUESTING
	});

	api({
		method: 'PATCH',
		url: `/api/patients/patient/${uuid}`,
		data: {
			guardianPassword: password,
			height,
			weight,
			...rest
		}
	})
		.then(response => {
			dispatch({
				type: PATIENT_SUCCESS,
				patient: response.data
			});
		})
		.catch(error => {
			dispatch({
				type: PATIENT_FAILURE,
				error
			});
			dispatch(errorHandler(error));
		});
};

export const updatePatient = (uuid, data, changed) => dispatch => {
	if (!changed.ht && !changed.wt) {
		return;
	}
	let height;
	let weight;
	try {
		weight = imperialToMetricWeight(
			parseFloat(data.weightUnitsPart1),
			parseFloat(data.weightUnitsPart2)
		);
		height = imperialToMetricLength(
			parseFloat(data.heightUnitsPart1),
			parseFloat(data.heightUnitsPart2)
		);
	} catch (error) {
		dispatch({
			type: PATIENT_FAILURE,
			error
		});
		dispatch(errorHandler(error));
		return;
	}

	dispatch({
		type: PATIENT_REQUESTING
	});

	api({
		method: 'PATCH',
		url: `/api/patients/patient/${uuid}`,
		data: {
			height,
			weight
		}
	})
		.then(response => {
			dispatch({
				type: PATIENT_SUCCESS,
				patient: response.data
			});
			let content = [];
			if (changed.ht) {
				content.push(` height changed to ${data.heightUnitsPart1}"${data.heightUnitsPart2}'`);
			}
			if (changed.wt) {
				content.push(`weight changed to ${data.weightUnitsPart1}lbs ${data.weightUnitsPart2}oz`);
			}
			if (!content.length) {
				return;
			}
			dispatch(postEncounterInfo(uuid, {
				content: `<p>Patient ${content.join(' and ')}<p>`,
				date: new Date().toISOString().split('T')[0]
			}));
			// The reason for this setTimeout is due to the fact that
			// the status returns as Processing when one calls the 
			// postEncounterInfo() function. For the UI to update the status
			// from processing to processed and to display the name of the
			// provider, we have to call getEncounterInfo but with a delay.
			// Since these are text/note encounters, the time taken to get it
			// processed is quick enough.
			setTimeout(() => {
				dispatch(getEncounterInfo(uuid))
			}, 2000);
		})
		.catch(error => {
			dispatch({
				type: PATIENT_FAILURE,
				error
			});
			dispatch(errorHandler(error));
		});
};

export const clearPatient = () => ({
	type: PATIENT_CLEAR
});

/*
 * Selectors
 */
export const getRequesting = state => get(state[reducerName], 'requesting');
export const getPatient = state => get(state[reducerName], 'patient');
export const getPasswordError = state =>
	get(state[reducerName], 'passwordError');
