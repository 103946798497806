import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { history } from 'store';
import {
	accessPatient,
	clearPatient,
	createPatient,
	getPatient
} from 'modules/patients/patient';
import {
	findPatient,
	getRequesting as getFindRequesting,
	getUuid,
	clearFind
} from 'modules/patients/find';
import {
	CAMPREFS,
	requestMedia,
	getCapture,
	getRequesting as getMediaRequesting,
	getFacingMode,
	setFacingMode
} from 'modules/media';
import { createStructuredSelector } from 'reselect';

// UI
import AddIcon from '@material-ui/icons/Add';
import { CircularProgress, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

import withSimple from 'layouts/withSimple';
import SmartFab from 'containers/SmartFab';
import MediaCapture from './MediaCapture';
import SVGMan from './SVGMan';

import PatientCreateDialog from './PatientCreateDialog';
import PatientPasswordDialog from './PatientPasswordDialog';

import store from 'utils/store';
import { CAMPFKEY } from '../../modules/media';

const styles = theme => ({
	root: {
		height: '100%', width: '100%', display: 'flex',
		justifyContent: 'center',	alignItems: 'center',
		flexDirection: 'column'
	},
	fab: {
		position: 'absolute',
		bottom: theme.spacing.unit * 2,
		left: 0, right: 0, margin: '0 auto'
	},
	svgContainer: {
		position: 'relative',
		background: `linear-gradient(${grey[200]}, ${grey[300]})`,
		borderRadius: '50%', overflow: 'hidden',
		marginBottom: theme.spacing.unit * 2
	},
	svg: {
		width: '35vh',
		display: 'block'
	},
	progress: {
		color: '#FFF'
	},
	absolute: {
		position: 'absolute',
		top: 0, right: 0, left: 0, bottom: 0,
		display: 'flex', justifyContent: 'center', alignItems: 'center',
		background: 'rgba(0,0,0,.25)'
	},
	last: {
		marginBottom: theme.spacing.unit * 8
	}
});

class Find extends Component {
	state = {files:{}, facingMode:store.getItem(CAMPFKEY)||CAMPREFS.E, camVer:store.getItem('camVer')||'0'};

	componentWillReceiveProps(nextProps) {
		//console.error({fn:`${__filename}:cwrp`, nextProps, cv:this.state.camVer});

		if (nextProps.capture && !this.props.capture) {
			this.props.findPatient(nextProps.capture);
		}

		if (nextProps.patient && nextProps.patient !== this.props.patient) {
			this.props.clearFind();
			history.push(`/patients/${nextProps.uuid}`);
		}
	}

	componentWillMount() {
		this.props.clearPatient();
	}

	handleChange = (evt) => {
		const tgt = evt.target;
		// console.error({fn:`${__filename}:hc`, evt, tgt, cp:this.state.camPref});
		if (tgt.type === 'checkbox' && tgt.name === 'chkCamPref') {
			const cp = (tgt.checked)?CAMPREFS.U:CAMPREFS.E;
			this.props.setFacingMode(cp);
		}
	};

	handleRequestMedia = () => {
		this.props.requestMedia();
	};

	render() {
		const { classes, requestingMedia, requestingFind, facingMode } = this.props;
		const useOldCam = !!!navigator.mediaDevices || !(typeof navigator.mediaDevices.getUserMedia === 'function') ||(this.state.camVer==='1');
		// console.log({f:'Findjs', useOldCam, fm:facingMode});

		return (
			<div className={classes.root}>
				<div className={classes.svgContainer}>
					<SVGMan className={classes.svg} />
					{requestingFind && (
						<div className={classes.absolute}>
							<CircularProgress size={48} />
						</div>
					)}
				</div>
				<Typography variant="h6">Biometric Identification</Typography>
				<Typography variant="caption" className={classes.last}>
					Take a picture of the patient's face
				</Typography>

				<PatientPasswordDialog />
				<PatientCreateDialog />

				{(useOldCam) &&
					<React.Fragment>
						<form>
							<label>Front Camera
								<input name="chkCamPref" type="checkbox" checked={facingMode===CAMPREFS.U} onChange={this.handleChange} />
							</label>
						</form>
						<input
							ref={e => {if (e) {this.input = e;}}}
							hidden={true} type='file' accept='image/*'
							capture={this.props.facingMode}
							onChange={e => {
								const t = e.target;
								const f = Object.assign({}, this.state.files);
								f.face  = t.files[0];
								this.setState({files:f}, ()=>{
									t.value='';
									this.props.findPatient(this.state.files.face);
								});
							}}
						/>
						<SmartFab position="center" onClick={() => {
							this.input.click();
							}} file={this.state}>
							<AddIcon />
						</SmartFab>
					</React.Fragment>
				}
				{(!useOldCam) &&
					<React.Fragment>
						<form>
							<label>Front Camera
								<input name="chkCamPref" type="checkbox" checked={this.props.facingMode===CAMPREFS.U} onChange={this.handleChange} />
							</label>
						</form>
						<SmartFab position="center" onClick={this.handleRequestMedia}>
							{requestingMedia ? (
								<CircularProgress className={classes.progress} size={24} />
							) : (
								<AddIcon />
							)}
						</SmartFab>
						<MediaCapture />
					</React.Fragment>
				}
			</div>
		);
	}
}

Find.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = createStructuredSelector({
	capture: getCapture,
	facingMode: getFacingMode,
	requestingMedia: getMediaRequesting,
	requestingFind: getFindRequesting,
	uuid: getUuid,
	patient: getPatient
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			accessPatient,
			createPatient,
			clearPatient,

			findPatient,
			clearFind,

			getFacingMode,
			setFacingMode,

			requestMedia
		},
		dispatch
	);

export default compose(
	withSimple,
	withStyles(styles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(Find);
