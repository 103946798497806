import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	download: {
		textDecoration: 'underline'
	},
	padding: {
		padding: '16px'
	}
});

class DownloadAsset extends PureComponent {
	render() {
		const { classes } = this.props;
		return (
			<div className={classes.padding}>
				{/* https://mathiasbynens.github.io/rel-noopener/ */}
				<a href={this.props.url} className={classes.download} target='_blank' rel='noopener noreferrer'>Click to download the asset</a>
			</div>
		);
	}
}

export default withStyles(styles)(DownloadAsset);
