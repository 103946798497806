function applyIndex(arr, k) {
  return arr.map((r) => {
    if (!r.field) {
      return r;
    }
    r.field = `${k}:${r.field}`;
    return r;
  });
}

export default function (err, k) {
  if (err.reasons) {
    return applyIndex(err.reasons, k);
  }
  if (err.json && err.json.reasons) {
    return applyIndex(err.json.reasons, k);
  }

  return [{
    text: 'Could not connect to the server.'
  }];
}
