import React from 'react';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit,
		border: `1px solid ${theme.palette.error.light}`
	},
	rootGutterBottom: {
		width: '100%',
		marginTop: theme.spacing.unit,
		border: `1px solid ${theme.palette.error.light}`,
		marginBottom: theme.spacing.unit
	},
	rootHidden: {
		display: 'none'
	},
	text: {
		color: theme.palette.error.main
	}
});

const ErrorList = props => {
	const { classes, reasons } = props;

	if (!reasons) {
		return false;
	}

	const reasonsArray = [];
	for (let i = 0; i < reasons.length; i++) {
		if (!reasons[i].field) {
			reasonsArray.push(
				<ListItem key={i}>
					<ListItemText
						disableTypography
						primary={
							<Typography className={classes.text}>
								{reasons[i].text}
							</Typography>
						}
					/>
				</ListItem>
			);
		}
	}

	let className = props.gutterBottom ? classes.rootGutterBottom : classes.root;
	className = reasonsArray.length ? className : classes.rootHidden;

	return (
		<List dense disablePadding className={className}>
			{reasonsArray}
		</List>
	);
};

export default withStyles(styles)(ErrorList);
