import get from 'lodash.get';
import store from 'utils/store';

export const CAMPREFS = {E:'environment', U:'user'};
export const CAMPFKEY = 'camPrefs';

/*
 * Actions
 */
const reducerName = 'media';
export const MEDIA_REGISTER_REFS = `${reducerName}/MEDIA_REGISTER_REFS`;
export const MEDIA_CLEAR = `${reducerName}/MEDIA_CLEAR`;
export const MEDIA_FMCHG = `${reducerName}/MEDIA_FMCHG`;
export const MEDIA_REQUESTING = `${reducerName}/MEDIA_REQUESTING`;
export const MEDIA_SUCCESS = `${reducerName}/MEDIA_SUCCESS`;
export const MEDIA_FAILURE = `${reducerName}/MEDIA_FAILURE`;
export const MEDIA_CAN_PLAY = `${reducerName}/MEDIA_CAN_PLAY`;
export const MEDIA_CAPTURED = `${reducerName}/MEDIA_CAPTURED`;
export const MEDIA_STOP = `${reducerName}/MEDIA_STOP`;

/*
 * Reducer
 */
const initialState = {
	requesting: false, ready: false,
	capture: null, stream: null,
	facingMode: store.getItem(CAMPFKEY) || CAMPREFS.E,
	videoRef: null, canvasRef: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case MEDIA_FMCHG:
			// console.error({f:'mediajs.def.fmc', afm:action.facingMode});
			return {...state, facingMode:action.facingMode};

		case MEDIA_REGISTER_REFS:
			return {...state,
				videoRef: action.videoRef, canvasRef: action.canvasRef
			};

		case MEDIA_REQUESTING:
			return {...state,	requesting:true, ready:action.ready,
				capture:null, stream:null
			};

		case MEDIA_SUCCESS:
			// console.error({f:'mediajs.def.ms', afm:action.facingMode});
			return {...state,	stream:action.stream, facingMode:action.facingMode};

		case MEDIA_CAPTURED:
			return {...state,	ready:false, capture:action.capture};

		case MEDIA_CAN_PLAY:
			return {...state, ready:true, requesting:false};

		case MEDIA_STOP:
			return {...state, stream: false};

		case MEDIA_FAILURE:
			return {...state};

		case MEDIA_CLEAR:
			return {...state, requesting: false, ready: false,
				capture: null, stream: null
			};

		default:
			return state;
	}
};

/*
 * Action Creators
 */
export const setFacingMode = (fm) => (dispatch, getState) => {
	const state = getState();
	let facingMode = getFacingMode(state);
	facingMode = fm;
	store.setItem(CAMPFKEY, facingMode);
	dispatch({type:MEDIA_FMCHG, facingMode});
	// console.error({f:'mediajs.sfm', fmls:store.getItem(CAMPFKEY), fmi:fm, fmo:facingMode, fmo2:getFacingMode(state)});
	return facingMode;
}

export const requestMedia = () => (dispatch, getState) => {
	const state = getState();
	const videoRef = getVideoRef(state);
	const canvasRef = getCanvasRef(state);
	let facingMode = getFacingMode(state);

	if (!videoRef || !canvasRef) {
		// @TODO: handle?
		console.error(`${__filename} vr=${videoRef} cr=${canvasRef}`);
		return;
	}

	dispatch({type: MEDIA_REQUESTING, ready:false});

	// if (toggleFacingMode) {
	//	facingMode = facingMode === CAMPREFS.E ? CAMPREFS.U : CAMPREFS.E;
	//	store.setItem(CAMPFKEY, facingMode);
	//}
	// console.error({f:'mediajs', facingMode});

	navigator.mediaDevices
		.getUserMedia({ video: { facingMode }, audio: false })
		.then(stream => {
			videoRef.current.srcObject = stream;
			videoRef.current.play();

			dispatch({type:MEDIA_SUCCESS, stream, facingMode});
		})
		.catch(error => {
			dispatch({type:MEDIA_FAILURE, error});
			dispatch(clearMedia());
		});
};

export const captureMedia = () => (dispatch, getState) => {
	const state = getState();
	const canvas = getCanvasRef(state).current;
	const video = getVideoRef(state).current;

	if (!canvas || !video) {
		// @TODO: handle?
		return;
	}

	const { width, height } = video.getBoundingClientRect();

	canvas.width = width;
	canvas.height = height;

	const context = canvas.getContext('2d');

	context.drawImage(video, 0, 0, width, height);

	canvas.toBlob(blob => {
		dispatch({type:MEDIA_CAPTURED, capture:blob});
		dispatch(stopMedia());
	});
};

export const mediaCanPlay = () => ({
	type: MEDIA_CAN_PLAY
});

export const registerRefs = (videoRef, canvasRef) => ({
	type: MEDIA_REGISTER_REFS, videoRef, canvasRef
});

export const stopMedia = () => (dispatch, getState) => {
	const state = getState();
	const stream = getStream(state);

	if (stream) {
		const tracks = stream.getTracks();

		if (tracks[0]) {
			tracks[0].stop();
		}
	}

	dispatch({type: MEDIA_STOP});
};

export const clearMedia = () => (dispatch, getState) => {
	dispatch(stopMedia());
	dispatch({type: MEDIA_CLEAR});
};

/*
 * Selectors
 */
export const getVideoRef =   state => get(state[reducerName], 'videoRef');
export const getCanvasRef =  state => get(state[reducerName], 'canvasRef');
export const getCapture =    state => get(state[reducerName], 'capture');
export const getStream =     state => get(state[reducerName], 'stream');
export const getReady =      state => get(state[reducerName], 'ready');
export const getFacingMode = state => get(state[reducerName], 'facingMode');
export const getRequesting = state => get(state[reducerName], 'requesting');
