import React, { PureComponent } from 'react';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import InstalledMessage from './InstalledMessage';

const styles = theme => ({
	close: {
		position: 'fixed',
		right: theme.spacing.unit,
		top: theme.spacing.unit
	}
});

class Installed extends PureComponent {
	render() {
		return <Dialog fullScreen open={true} onClose={this.props.onClose}>
			<CloseIcon
				onClick={this.props.onClose}
				className={this.props.classes.close}
			/>
			<InstalledMessage />
		</Dialog>;
	}
}

export default withStyles(styles)(Installed);
