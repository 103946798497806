import React, { PureComponent } from 'react';
import VodPlayer from 'components/VodPlayer';

class Video extends PureComponent {
	render() {
		return (
			<VodPlayer posterURI={this.props.thumb} manifest={this.props.manifest} />
		);
	}
}

export default Video;
