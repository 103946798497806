import React, { Fragment } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import store, { history } from 'store';
import App from './App';
import * as serviceWorker from './service-worker';
import A2hs from 'components/add-to-home-screen/Main';

const theme = createMuiTheme({
	palette: {
		primary: {
			contrastText: '#FFF',
			light: '#3E5F22',
			main: '#629937',
			dark: '#486E27',
			icon: '#efefef',
			iconBackground: '#6EA240'
		}
	}
});

const target = document.querySelector('#root');

render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<MuiThemeProvider theme={theme}>
				<Fragment>
					<CssBaseline />
					<Fragment>
						<App />
						<A2hs />
					</Fragment>
				</Fragment>
			</MuiThemeProvider>
		</ConnectedRouter>
	</Provider>,
	target
);

serviceWorker.register();
