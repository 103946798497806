import React, { PureComponent } from 'react';

// Redux
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';

import {
	getNewUser,
	clearDisplayName,
	createNewUser,
	getNewUserPhoneNumber,
	getRequesting
} from 'modules/authentication/code';

// UI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

class NewUserForm extends PureComponent {
	state = {
		displayName: ''
	};

	handleOnChange = key => e => {
		this.setState({
			[key]: e.target.value
		});
	};

	handleOnClose = () => {
		this.setState({
			displayName: ''
		});
		this.props.clearDisplayName();
	};

	handleOnSubmit = e => {
		e.preventDefault();
		const { getNewUserPhoneNumber, createNewUser } = this.props;
		const { displayName } = this.state;
		createNewUser(displayName, getNewUserPhoneNumber());
	};

	render() {
		const { newUser } = this.props;
		const { displayName, requesting } = this.state;

		const open = Boolean(newUser);

		return (
			<Dialog open={open} onClose={this.handleOnClose}>
				<form onSubmit={this.handleOnSubmit}>
					<DialogTitle id="form-dialog-title">New User</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Enter a display name to sign up.
						</DialogContentText>
						<TextField
							autoFocus
							autoComplete="off"
							onChange={this.handleOnChange('displayName')}
							value={displayName}
							id="displayName"
							label="Display Name"
							margin="dense"
							required
							fullWidth
							type="text"
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleOnClose} color="primary">
							Cancel
						</Button>
						<Button
							type="submit"
							onClick={this.handleOnSubmit}
							variant="contained"
							color="primary"
							disabled={!displayName || requesting}
						>
							{requesting ? (
								<CircularProgress style={{ color: '#FFF' }} size={24} />
							) : (
								'Submit'
							)}
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	newUser: getNewUser,
	requesting: getRequesting
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			clearDisplayName,
			getNewUserPhoneNumber,
			createNewUser
		},
		dispatch
	);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(NewUserForm);
