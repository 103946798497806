import React from 'react';
import Full from './Full';

function withLayout(Component) {
	function WithLayout(props) {
		const { classes, ...rest } = props;

		return (
			<Full {...rest}>
				<Component {...props} />
			</Full>
		);
	}

	return WithLayout;
}

export default withLayout;
