import React, { PureComponent } from 'react';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import withFull from 'layouts/withFull';

const styles = theme => ({
	root: {
		textAlign: 'center'
	}
});

class InstalledMessage extends PureComponent {
	render() {
		const { classes } = this.props;

		return <div className={classes.root}>
			<Typography variant='h6'>
        		Application successfully installed
			</Typography>
			<Typography variant='body1'>
				Please launch Recare from the home screen
			</Typography>
		</div>
	}
}

export default withStyles(styles)(withFull(InstalledMessage));
