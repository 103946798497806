import React, { PureComponent } from 'react';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = () => ({
	container: {
		position: 'relative',
		width: '100%',
		paddingBottom: '56.25%',
		backgroundColor: '#EEE'
	},
	absolute: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0
	},
	icon: {
		fontSize: 64,
		color: '#FFF'
	}
});

class Asset extends PureComponent {
	render() {
		const { classes } = this.props;

		return (
			<div className={classes.container}>
				<div className={classes.absolute}>
					<CircularProgress size={24} />
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(Asset);
