import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Asset from 'components/assets/Asset';
import Processing from 'components/assets/Processing';
import Video from 'components/assets/Video';
import Image from 'components/assets/Image';
import VodPlayer from 'components/VodPlayer';
import DownloadAsset from 'components/assets/DownloadAsset';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Dotdotdot from 'react-dotdotdot';
import striptags from 'striptags';

const styles = theme => ({
	actions: {
		display: 'flex',
		paddingTop: 0,
		paddingLeft: theme.spacing.unit * 2,
		paddingRight: theme.spacing.unit * 2
	},
	expand: {
		marginLeft: 'auto',
		float: 'right',
		width: '30%'
	},
	root: {
		width: '80%',
		marginRight: theme.spacing.unit,
		display: 'inline-block',
		marginBottom: theme.spacing.unit / 2 // safari
	},
	media: {
		height: 0,
		paddingTop: '56.25%' // 16:9
	},
	grow: {
		flexGrow: 1
	},
	iconButton: {
		marginRight: -12
	},
	content: {
		padding: theme.spacing.unit * 2,
		paddingTop: 0,
		overflowWrap: 'break-word'
	},
	listItem: {
		display: 'block', // safari
		width: '70%',
		float: 'left'
	},
	para: {
		position: 'relative',
		backgroundColor: '#f5f5f5', // white smoke color
		height: 0,
		paddingTop: '56.25%' // 16:9
	},
	notePreview: {
		whiteSpace: 'normal',
		overflow: 'hidden',
		position: 'relative'
	},
	previewContent: {
		width: '62%',
		top: 0,
		position: 'absolute',
		paddingLeft: theme.spacing.unit * 2,
		paddingRight: theme.spacing.unit * 2
	},
	fixHt: {
		minHeight: '75vh',
		maxHeight: '75vh',
		minWidth: '75vw',
		maxWidth: '75vw'
	},
	listItemFullWidth: {
		width: '100%',
		display: 'block' // safari
	}
});

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class EncounterCard extends Component {
	state = {
		open: false
	};

	get date() {
		return moment.utc(this.props.date).format('ll');
	}

	get providerName() {
		if (!this.props.provider || !this.props.provider.name) {
			return 'Processing...';
		}

		return this.props.provider.name
			.split(' ')
			.map(s => s.charAt(0).toUpperCase() + s.substring(1))
			.join(' ');
	}

	handleOpen = () => {
		this.setState({
			open: true
		});
	};

	handleClose = () => {
		this.setState({
			open: false
		});
	};

	render() {
		const { classes, upload, content } = this.props;
		const { open } = this.state;
		let media = false;
		let type = 'Note';

		let preview = <Asset {...upload} />;
		if (!upload) {
			preview = (
				<div className={classes.para}>
					<div className={classes.previewContent}>
						{/* Please note that react-dotdotdot does not work with nested markup */}
						<Dotdotdot clamp={4}>
							<p className={classes.notePreview}>{striptags(content)}</p>
						</Dotdotdot>
					</div>
				</div>
			);
		}
		if (upload) {
			if (!upload.processed) {
				preview = <Processing {...upload} />;
				type = 'Processing...';
			} else if (upload.type === 'image') {
				media = <Image {...upload} />;
				preview = <CardMedia className={classes.media} image={upload.url} />;
				type = 'Image';
			} else if (upload.type === 'video') {
				media = <Video {...upload} />;
				preview = (
					<VodPlayer
						stop={this.state.open}
						posterURI={upload.thumb}
						manifest={upload.manifest}
					/>
				);
				type = 'Video';
			} else {
				media = <DownloadAsset {...upload} />;
			}
		}

		return (
			<Fragment>
				<Card className={classes.root}>
					{preview}
					<List>
						<ListItem dense className={classes.listItem}>
							<ListItemText primary={this.providerName} secondary={type} />
						</ListItem>
					</List>
					<CardActions className={classes.actions}>
						<IconButton
							className={classes.expand}
							onClick={this.handleOpen}
							aria-label="Show more"
						>
							<ExpandMoreIcon />
						</IconButton>
					</CardActions>
				</Card>
				<Dialog
					open={open}
					onClose={this.handleClose}
					TransitionComponent={Transition}
					classes={{ paper: classes.fixHt }}
				>
					<AppBar position="static" className={classes.appBar}>
						<Toolbar>
							<Typography variant="h6" color="inherit" className={classes.grow}>
								{this.date}
							</Typography>
							<IconButton
								className={classes.iconButton}
								color="inherit"
								onClick={this.handleClose}
							>
								<CloseIcon />
							</IconButton>
						</Toolbar>
					</AppBar>
					{media}
					<List>
						<ListItem dense divider className={classes.listItemFullWidth}>
							<ListItemText primary={this.providerName} secondary={type} />
						</ListItem>
					</List>
					<div className={classes.content}>
						<div dangerouslySetInnerHTML={{ __html: this.props.content }} />
					</div>
				</Dialog>
			</Fragment>
		);
	}
}

EncounterCard.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default compose(
	withStyles(styles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(EncounterCard);
