import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	actions: {
		marginTop: theme.spacing.unit * 2
	}
});

class Actions extends React.PureComponent {
	render() {
		const { classes } = this.props;
		const textAlign = this.props.align || 'right';

		return (
			<div className={classes.actions} style={{ textAlign }}>
				{this.props.children}
			</div>
		);
	}
}

export default withStyles(styles)(Actions);
