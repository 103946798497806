import React, { Component } from 'react';
import PropTypes from 'prop-types';

// UI
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import { grey } from '@material-ui/core/colors';

const styles = theme => ({
	root: {
		background: `linear-gradient(45deg, ${grey[100]} 30%, ${grey[50]} 70%)`,
		height: '100vh',
		width: '100vw',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	}
});

class Loading extends Component {
	render() {
		const { classes } = this.props;

		return (
			<div className={classes.root}>
				<Fade in>
					<CircularProgress />
				</Fade>
			</div>
		);
	}
}

Loading.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Loading);
