import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
	clearEncounterSave,
	getEncounterInfo,
	postEncounterInfo,
	getRequesting
} from 'modules/encounters/encounters';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddEncounterButton from './AddEncounterButton';
import AddEncounterDialog from './AddEncounterDialog';
import EncounterBlock from './EncounterBlock';

const styles = theme => ({
	missing: {
		marginTop: theme.spacing.unit * 7,
		padding: theme.spacing.unit
	},
	topPadding: {
		paddingTop: theme.spacing.unit * 4
	}
});

class Encounters extends Component {
	state = { add: false, utlRfrshPndEnc: 0, utlRfrshPndEncTo: null };

	utlRfrshPndingEnc() {
		this.props.getEncounterInfo(this.props.patient.uuid);
		this.setState({ utlRfrshPndEncTo: null });

		let hasUnprocd = false;
		for (let ed = 0; ed < this.props.encounters.length; ++ed) {
			// by day
			const encByDay = this.props.encounters[ed];
			for (let en = 0; en < encByDay.info.length; ++en) {
				const enc = encByDay.info[en];
				if (enc.upload && !enc.upload.processed) {
					hasUnprocd = true;
				}
			}
		}
		const pollInts = [12000, 8000, 4000];
		this.setState({ utlRfrshPndEnc: this.state.utlRfrshPndEnc + 1 });
		if (hasUnprocd) {
			this.setState({
				utlRfrshPndEncTo: setTimeout(
					this.utlRfrshPndingEnc.bind(this),
					pollInts[this.state.utlRfrshPndEnc % pollInts.length]
				)
			});
		} else {
			this.setState({ utlRfrshPndEnc: 0 });
		}
	}

	componentWillMount() {
		// this.props.getEncounterInfo(this.props.patient.uuid);
		this.utlRfrshPndingEnc();
	}

	componentWillUnmount() {
		if (this.state.utlRfrshPndEncTo) {
			clearTimeout(this.state.utlRfrshPndEncTo);
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.state.add && !this.props.saved && nextProps.saved) {
			this.setState({ add: false }, () => {
				this.props.clearEncounterSave();
				this.utlRfrshPndingEnc();
			});
		}
	}

	render() {
		const { classes, requesting } = this.props;

		return (
			<div>
				<List className={classes.topPadding}>
					{this.props.encounters.length ? (
						this.props.encounters.map(e => {
							return (
								<EncounterBlock
									key={`${e.date}-${e.info.length}`}
									info={e.info}
									date={e.date}
								/>
							);
						})
					) : (
						<div>
							<Typography
								className={classes.missing}
								align="center"
								variant="subheading"
							>
							{requesting ? (<CircularProgress size={48} className={classes.progress} />) : ('No visit information yet')}
							</Typography>
						</div>
					)}
				</List>

				<AddEncounterDialog
					onDismiss={() => {
						this.setState({ add: false });
					}}
					onSubmit={data => {
						this.props.postEncounterInfo(this.props.patient.uuid, data);
						// console.error({aedos:{puuid:this.props.patient.uuid, os:data}});
					}}
					open={this.state.add}
				/>
				<br />
				<br />
				<AddEncounterButton
					onClick={() => {
						this.setState({ add: true });
					}}
				/>
			</div>
		);
	}
}

Encounters.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = createStructuredSelector({
	saved: function(state) { return state.encounters.saved},
	encounters: function(state) { return state.encounters.encounters},
	patient: function(state) { return state.patient.patient},
	requesting: getRequesting
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			clearEncounterSave,
			getEncounterInfo,
			postEncounterInfo
		},
		dispatch
	);

export default compose(
	withStyles(styles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(Encounters);
