import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { clearCode } from 'modules/authentication/code';
import {
	requestCodeAuthentication,
	getRequesting
} from 'modules/authentication';
import { createStructuredSelector } from 'reselect';

// UI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import { withStyles } from '@material-ui/core/styles';

import { Action, Actions } from 'components/actions';
import ErrorList from 'components/error/List';

const styles = theme => ({
	codeInput: {
		textAlign: 'center',
		width: theme.spacing.unit * 4,
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
		].join(',')
	},
	formContainer: {
		maxWidth: theme.breakpoints.values.sm,
		margin: '0 auto'
	},
	inputContainer: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	progress: {
		color: '#FFF'
	}
});

class CodeForm extends Component {
	constructor() {
		super();

		this.input0 = React.createRef();
		this.input1 = React.createRef();
		this.input2 = React.createRef();
		this.input3 = React.createRef();
	}

	state = { code: ['', '', '', ''] };

	handleSubmit = e => {
		e.preventDefault();

		const { requestCodeAuthentication } = this.props;
		const { code } = this.state;

		requestCodeAuthentication(code.join(''));
	};

	handleOnBack = () => {
		const { clearCode } = this.props;

		clearCode();
	};

	handleOnChange = i => e => {
		// Handle the copy-paste from clipboard.
		if (e.target.value.length === 4) {
			
			const CODELEN = 4;
			const inp = new Array(CODELEN);
			for (let i = 0; i < CODELEN; ++i) {
				inp[i] = String(e.target.value).slice(i, i+1);
			}
			this.setState(prevState => {
				const code = prevState.code.slice();

				for (let i = 0; i < CODELEN; ++i) {
					code[i] = inp[i];
				}
				const nextInput = this.input3;
				nextInput.current.focus();
				return { code };
			});
		} else {
			const nextV = String(e.target.value).slice(-1);
			this.setState(prevState => {
				const code = prevState.code.slice();
				code[i] = nextV;
				return { code };
			});

			if (nextV === '') {
				return;
			}

			// Focus next input
			const nextInput = this[`input${i + 1}`];
			if (nextInput) {
				nextInput.current.focus();
			}
		}
	};

	handleOnKeyDown = i => e => {
		const code = this.state.code.slice();
		const back = e.keyCode === 8 || e.keyCode === 46;

		if (code[i] === '' && back) {
			const nextInput = this[`input${i - 1}`];
			if (nextInput) {
				nextInput.current.focus();
			}
		}
	};

	renderCodeInput(i) {
		const { classes } = this.props;

		return (
			<TextField
				variant="outlined"
				id={`code${i}`}
				autoFocus={i === 0}
				value={this.state.code[i]}
				type="number"
				autoComplete="one-time-code"
				InputProps={{
					classes: {
						input: classes.codeInput
					}
				}}
				InputLabelProps={{
					shrink: true
				}}
				label={i === 0 ? 'Code' : null}
				inputRef={this[`input${i}`]}
				onKeyDown={this.handleOnKeyDown(i, this)}
				onChange={this.handleOnChange(i, this)}
			/>
		);
	}

	render() {
		const { classes, reasons, requesting } = this.props;
		const { code } = this.state;

		const valid = code.every(c => c !== '');

		return (
			<form onSubmit={this.handleSubmit}>
				<ErrorList reasons={reasons} />
				<div className={classes.formContainer}>
					<div className={classes.inputContainer}>
						{this.renderCodeInput(0)}
						{this.renderCodeInput(1)}
						{this.renderCodeInput(2)}
						{this.renderCodeInput(3)}
					</div>

					<Actions align="right">
						<Action>
							<Button color="primary" onClick={this.handleOnBack}>Back</Button>
						</Action>
						<Action>
							<Button	color="primary" disabled={!valid}
								type="submit"	variant="contained">
								{requesting ? (
									<CircularProgress size={24} className={classes.progress} />
								) : (
									'Next'
								)}
							</Button>
						</Action>
					</Actions>
				</div>
			</form>
		);
	}
}

CodeForm.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = createStructuredSelector({
	requesting: getRequesting
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			clearCode,
			requestCodeAuthentication
		},
		dispatch
	);

export default compose(
	withStyles(styles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(CodeForm);
