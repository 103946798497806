import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isMobilePhone from 'validator/lib/isMobilePhone';

// Redux
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { requestCode, getRequesting, findUser } from 'modules/authentication/code';

// UI
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import MuiPhoneNumber from 'material-ui-phone-number';
import { Action, Actions } from 'components/actions';
import NewUserForm from './NewUserForm';

const styles = theme => ({
	progress: {
		color: '#FFF'
	}
});

class PhoneNumberForm extends Component {
	state = {
		phoneNumber: '',
		valid: false
	};

	handleSubmit = e => {
		e.preventDefault();
		this.props.findUser(this.state.phoneNumber);
	};

	handleChange = phoneNumber => {
		this.setState({
			valid: isMobilePhone(phoneNumber),
			phoneNumber
		});
	};

	render() {
		const { classes, requesting } = this.props;
		const { phoneNumber, valid } = this.state;

		return (
			<div>
				<form onSubmit={this.handleSubmit}>
					<FormControl fullWidth className={classes.margin}>
						<MuiPhoneNumber label="Enter your mobile number"
							autoFocus defaultCountry="us" disableAreaCodes
							value={phoneNumber} onChange={this.handleChange}
							variant="outlined"
						/>
					</FormControl>

					<Actions align="right">
						<Action>
							<Button color="primary" type="submit" variant="contained"
								disabled={!valid}
							>
								{requesting ? (
									<CircularProgress size={24} className={classes.progress} />
								) : (
									'Next'
								)}
							</Button>
						</Action>
					</Actions>
				</form>
				<NewUserForm />
			</div>
		);
	}
}

PhoneNumberForm.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = createStructuredSelector({
	requesting: getRequesting
});

const mapDispatchToProps = dispatch =>
	bindActionCreators({requestCode, findUser}, dispatch);

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps)
)(PhoneNumberForm);
