import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import Fade from '@material-ui/core/Fade';
import SvgLogo from 'components/logo/svg';

import Viewport from 'components/Viewport';

const styles = theme => ({
	flex: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		height: '50vh',
		width: '100vw'
	},
	content: {
		padding: theme.spacing.unit * 3
	},
	logo: {
		width: theme.spacing.unit * 16,
		marginBottom: theme.spacing.unit * 3
	},
	name: {
		fontWeight: 700,
		color: grey[700]
	},
	slogan: {
		color: grey[400]
	}
});

class SimpleLayout extends Component {
	constructor() {
		super();

		this.rootRef = React.createRef();
	}

	render() {
		const { classes, children } = this.props;

		return (
			<Viewport>
				<Fade in>
					<div>
						<div className={classes.flex}>
							<SvgLogo className={classes.logo} />
							<Typography align="center" variant="h4" className={classes.name}>
								RECARE
							</Typography>
							<Typography
								align="center"
								variant="subtitle2"
								className={classes.slogan}
							>
								Protecting Patient Data
							</Typography>
							<Typography
								align="center"
								variant="subtitle2"
								className={classes.slogan}
							>
								Throughout The World
							</Typography>
						</div>
						<div className={classes.content}>{children}</div>
					</div>
				</Fade>
			</Viewport>
		);
	}
}

export default withStyles(styles)(SimpleLayout);
